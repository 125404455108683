import { prefixRoutes } from '@/router/utils'
import { defineAsyncComponent } from 'vue'

const Receive = defineAsyncComponent(() => import('./Receive.vue'))

export const receiveRoute = (
  resource,
  children,
  component = Receive,
  type = '(\\d+)',
  key: string='id',
  resourceKey: string='id',
) => prefixRoutes(`:${key}${type}`, children, { component, props: { resource, routeKey: key, resourceKey } })
