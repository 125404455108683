import {
  FacebookAuthProvider,
  signInWithRedirect,
  Auth,
} from 'firebase/auth';

import { firebaseLogout } from '@/modules/firebase/logout';

export function applySignIn(auth: typeof Auth): void {
  if (auth.currentUser) {
    firebaseLogout();
  }
  
  const provider = new FacebookAuthProvider();
  // provider.addScope('email');
  // provider.addScope('name');

  signInWithRedirect(auth, provider);
}

export function toggleFacebookSignIn(signInButton: HTMLElement, auth: typeof Auth): void {
  signInButton.addEventListener('click', () => applySignIn(auth), false);
}
