<template>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<g id="vuesax/linear/logout">
<g id="logout">
<path id="Vector" d="M13.0801 10.965L15.0001 9.045L13.0801 7.125" stroke="#F1F8FE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M7.32007 9.04688H14.9476" stroke="#F1F8FE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_3" d="M8.82007 15C5.50507 15 2.82007 12.75 2.82007 9C2.82007 5.25 5.50507 3 8.82007 3" stroke="#F1F8FE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
</svg>
</template>