import { ClassValue } from 'clsx'
import { block, BemBlockConfig } from '@aspectus/bem'
import { makeClassName, isClassName, BemClassFactoryConfig, BemElement } from './classname'
type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
}
export type OptionalStyleRules<
  ClassDefinition extends any = BemElement,
  ClassKey extends string = string
> = PartialRecord<ClassKey, ClassDefinition>
export type StyleRules<
  ClassDefinition extends any = BemElement,
  ClassKey extends string = string
> = Record<ClassKey, ClassDefinition>
export type BemClassesConfig = BemBlockConfig & BemClassFactoryConfig
export type BemClassesMixFunction<
  ClassDefinition extends any = BemElement,
  ClassKey extends string = string
> = (mix?: OptionalStyleRules<ClassDefinition | ClassValue, ClassKey>) => StyleRules<ClassDefinition, ClassKey>

/**
 * Classes object generator factory.
 * Produces factory for extendable classes usage.
 *
 * Most useful feature is to use inside different components.
 *
 * ```js
 * import { makeClasses } from '@aspectus/bem-classes'
 *
 * const CONFIG = { n: '', e: '__', m: '--', v: '_', sp: 'is-', sv: '_' }
 * // This call creates classes factory:
 * const useClasses = makeClasses(CONFIG, 'block', ['header', 'footer'])
 * // Factory can be used with additional classes:
 * const mixedClasses = useClasses({
 *   root: 'different',
 *   footer: ['other', { boolean: true }]
 * })
 * // Or without:
 * const classes = useClasses()
 * ```
 *
 * And as a result all the classes objects can be used to describe your markup:
 *
 * - Mixed:
 *
 *   ```js
 *   console.log(mixedClasses.root.value)
 *   // > 'block different'
 *   console.log(mixedClasses.header())
 *   // > 'block__header'
 *   console.log(mixedClasses.footer().m({ mixed: true }))
 *   // > 'block__footer block__footer--mixed other boolean'
 *   ```
 * - Raw:
 *   ```js
 *   console.log(classes.root.s({ raw: true }).value)
 *   // > 'block is-raw'
 *   console.log(classes.header())
 *   // > 'block__header'
 *   console.log(classes.footer())
 *   // > 'block__footer'
 *   ```
 *
 * @category General
 * @export
 * @template ClassKey - String for class keys definition.
 * @template ClassDefinition - Type of classes result.
 * @param config - Name prefixes/delimiters.
 * @param blockName - String that defines base block class name.
 * @param elements - List of element names(strings) that will be autogenerated.
 * @returns Factory for classes object definition.
 *
 *   Classes object definition will be object with properties:
 *
 *   - `root` - `makeClassName` object for `blockName`.
 *   - `{element}` - For each element name in `elements` there will be
 *     a corresponding property with `makeClassName` object stored in it.
 */
export function makeClasses<
  ClassKey extends string,
  ClassDefinition extends BemElement
> (
  config: BemClassesConfig,
  blockName: string,
  elements: ClassKey[] = []
): BemClassesMixFunction<ClassDefinition, ClassKey | 'root'> {
  type Key = ClassKey | 'root'
  const names = <Key[]>['root'].concat(elements)
  const rootDefinition = {
    root: makeClassName(config, blockName)
  }
  const initialDefinition = elements.reduce((acc: any, elementName: ClassKey) => {
    acc[elementName] = makeClassName(
      config, block(config, blockName, elementName, {}).join(' ')
    )

    return acc
  }, rootDefinition)

  return (mix: OptionalStyleRules<ClassDefinition | ClassValue, Key> = <any>{}): StyleRules<ClassDefinition, Key> => (
    names
      .filter(key => !!mix[key])
      .reduce((acc, elementName: Key) => {
        acc[elementName] = acc[elementName].mix(
          isClassName(mix[elementName])
            ? (<BemElement>mix[elementName])()
            : mix[elementName]
        )

        return acc
      }, { ...initialDefinition })
  )
}
