import {
  GoogleAuthProvider,
  signInWithRedirect,
  Auth
} from 'firebase/auth';

import { firebaseLogout } from '@/modules/firebase/logout';

export function applySignIn(auth: typeof Auth): void {
  console.log(auth, auth.currentUser);
  if (auth.currentUser) {
    firebaseLogout();
  }
  
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  provider.addScope('https://www.googleapis.com/auth/plus.login');
  
  signInWithRedirect(auth, provider);
}

export function toggleGoogleSignIn(signInButton: HTMLElement, auth: typeof Auth): void {
  signInButton.addEventListener('click', () => applySignIn(auth), false);
}
