<svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/sms">
<g id="sms">
<path id="Vector" d="M35.4166 43.5768H14.5833C8.33329 43.5768 4.16663 40.4518 4.16663 33.1602V18.5768C4.16663 11.2852 8.33329 8.16016 14.5833 8.16016H35.4166C41.6666 8.16016 45.8333 11.2852 45.8333 18.5768V33.1602C45.8333 40.4518 41.6666 43.5768 35.4166 43.5768Z" stroke="url(#paint0_linear_1766_2057)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M35.4167 19.6172L28.8959 24.8255C26.75 26.5339 23.2292 26.5339 21.0834 24.8255L14.5834 19.6172" stroke="url(#paint1_linear_1766_2057)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_1766_2057" x1="54.5438" y1="-33.911" x2="79.7783" y2="35.5467" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
<linearGradient id="paint1_linear_1766_2057" x1="39.772" y1="11.9083" x2="41.6567" y2="26.0643" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
</defs>
</svg>