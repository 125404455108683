<template>

<svg x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve" v-bind="$attrs">
  <line fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="15" y1="8" x2="1" y2="8"/>
	<g>

    <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="3.2" y1="11.5" x2="3.2" y2="12"/>
		<polyline fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" points="3.2,14.2 3.2,14.7 3.7,14.7"/>

    <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.8091,2.4273" x1="6.1" y1="14.7" x2="11.4" y2="14.7"/>
		<polyline fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" points="12.6,14.7 13.1,14.7 13.1,14.2"/>

    <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="13.1" y1="12" x2="13.1" y2="11.5"/>
	</g>
  <polyline fill="none" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" stroke-miterlimit="10" points="13.1,4.4 13.1,1.3 3.2,1.3 3.2,4.4 "/>
</svg>

</template>
