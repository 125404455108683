import { r, createSender } from '@/plugins/resource/resource'

const MODEL = 'account'

export const accountApi = {
  retrieve: r('/detail/', MODEL),
  communicationLanguages: r('/communication-language/list/', MODEL),
  update: r('/detail/', MODEL, createSender).config('method', 'PATCH'),
  email: {
    change: r('/email/change/', MODEL, createSender),
    requestConfirmation: r('/email/request-confirmation/', MODEL, createSender),
  },
  phone: {
    change: r('/phone/change/', MODEL, createSender),
    requestConfirmation: r('/phone/request-confirmation/', MODEL, createSender),
  },
  changePassword: r('/password-change/', MODEL, createSender),
}
 