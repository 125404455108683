import type { App } from 'vue'

import VueFinalModal from './x001-vue-final-modal'
import Recaptcha from './x002-vue-recaptcha'
import Countdown from './x003-vue-countdown'
import Notification from './x005-vue-notification'
import SocialSharing from './x006-vue-social-sharing'
import Swiper from './x007-vue-swiper'
import TouchEvents from './x008-vue-touch-events'
import GoogleMaps from './x009-vue-google-maps'
import flatPickr from './x010-vue3-flatpickr'
import tabs from './x011-vue-tabs'
import quill from './x012-ckeditor'
import prime from './x014-prime'
import clipboard from './x013-vue3-clipboard'
import ResourceComponents from './resource'
import OtpComponent from './otp/component.vue'

export default {
  install(app: App<Element>): void {
    app
      .use(VueFinalModal)
      .use(Recaptcha)
      .use(Countdown)
      .use(Notification)
      .use(SocialSharing)
      .use(Swiper)
      .use(TouchEvents)
      .use(GoogleMaps)
      .use(ResourceComponents)
      .use(flatPickr)
      .use(tabs)
      .use(quill)
      .use(clipboard)
      .use(prime)
      .component('OtpComponent', OtpComponent)
  }
}