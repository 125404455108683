import type { App } from 'vue'
import Components from './components';

function install(app: App<Element>): void {
  app.use(Components);
}

export * from './resource';
export default {
  install,
};

