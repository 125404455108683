import { computed, defineComponent, h, onMounted, ref, watch } from 'vue'

export default defineComponent({
  name: 'infinite-template-results-collector',

  props: {
    items: {
      type: String,
    },
    pagination: Object as Record<string, any>,
    isNewPage: Boolean,
  },
  setup(
    props: {
      items: string
      pagination: Record<string, any>
      isNewPage: boolean
    },
    { slots }
  ) {
    const cache = ref<{ key: number; value: any }[]>([])
    const isSSR = ref<boolean>(true)

    const collected = computed(() => {
      return cache.value
    })

    watch(
      () => props.items,
      (nval) => {
        itemsReceived(nval)
      }
    )

    onMounted(() => {
      if (props.items) {
        itemsReceived(props.items)
      }
    })

    function itemsReceived(items: string) {
      if (
        props.isNewPage ||
        (props.pagination && 0 === props.pagination.offset)
      ) {
        isSSR.value = false
      }

      if (items && items.length) {
        if (props.isNewPage) {
          cache.value = []
        }
        cache.value.push({
          key: Date.now(),
          value: props.items,
        })
      }
    }

    return () => [
      slots.ssr?.({
        isSSR: isSSR.value,
      }),
      slots?.default?.({
        items: collected.value,
      }),
    ]
  },
})
