<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 18.86H17.24C16.44 18.86 15.68 19.17 15.12 19.73L13.41 21.42C12.63 22.19 11.36 22.19 10.58 21.42L8.87 19.73C8.31 19.17 7.54 18.86 6.75 18.86H6C4.34 18.86 3 17.53 3 15.89V4.97C3 3.33 4.34 2 6 2H18C19.66 2 21 3.33 21 4.97V15.88C21 17.52 19.66 18.86 18 18.86Z" stroke="url(#paint0_linear_3924_13307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0691 8.95005C12.0291 8.95005 11.9691 8.95005 11.9191 8.95005C10.8691 8.91005 10.0391 8.06005 10.0391 7.00005C10.0391 5.92005 10.9091 5.05005 11.9891 5.05005C13.0691 5.05005 13.9391 5.93005 13.9391 7.00005C13.9491 8.06005 13.1191 8.92005 12.0691 8.95005Z" stroke="url(#paint1_linear_3924_13307)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.2475 11.9601C7.9175 12.8501 7.9175 14.3001 9.2475 15.1901C10.7575 16.2001 13.2375 16.2001 14.7475 15.1901C16.0775 14.3001 16.0775 12.8501 14.7475 11.9601C13.2375 10.9601 10.7675 10.9601 9.2475 11.9601Z" stroke="url(#paint2_linear_3924_13307)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_3924_13307" x1="24.7629" y1="-21.7549" x2="41.9651" y2="14.4714" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3924_13307" x1="14.7545" y1="0.417271" x2="17.8834" y2="7.738" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint2_linear_3924_13307" x1="17.3118" y1="5.58245" x2="19.96" y2="15.3843" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>