<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<g clip-path="url(#clip0_2730_22109)">
<path d="M7.9987 1.83398V4.50065M10.7987 5.70065L12.732 3.76732M11.9987 8.50065H14.6654M10.7987 11.3007L12.732 13.234M7.9987 12.5007V15.1673M3.26536 13.234L5.1987 11.3007M1.33203 8.50065H3.9987M3.26536 3.76732L5.1987 5.70065" stroke="#FFA31A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2730_22109">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
</template>