import { Component, App, h, SetupContext, VNode, defineAsyncComponent } from 'vue'

import MazSelect from 'maz-ui/components/MazSelect'

import Controller from '@/components/controls/BaseDescriptor.vue'
import InputLabel from '@/components/controls/LabelComponent.vue'
import InputTextArea from '@/components/controls/TextArea.vue'
import SimpleInput from '@/components/controls/SimpleInput.vue'
import Multiselect from '@/components/controls/MutiselectInput.vue'
import SingleSelect from '@/components/controls/SelectInput.vue'
import Recaptcha from '@/components/controls/RecaptchaComponent.vue'
import OtpInput from '@/components/controls/OtpInput.vue'
import Image from '@/components/controls/Image.vue'
import File from '@/components/controls/File.vue'
import CButton from './RootElement.vue'
import CLabel from './CLabel.vue'
import Checker from './Checker'
import BaseSelect from './CSelect.vue'
import InputElement from './RootInput.vue'
// import PhoneInput from './PhoneInput.vue'
import CDescriptor from './CDescriptor.vue'
import { CDatePicker } from './BaseDatepicker'
import Flatpickr from './Flatpickr.vue'
import Switch from './Switch.vue'
import FakeInput from './FakeInput.vue'
import Editor from './Editor.vue'
import Inliner from './Inliner.vue'
import SocialNetwork from './SocialNetwork.vue'
import DummyTag from './DummyTag.vue'
import CityMapAutocomplete from './CityMapAutocomplete.vue'
import MultilangInput from './MultilangInput.vue'
import MazSelectInput from './MazSelectInput.vue'
import MultiselectSelections from './MultiselectSelections.vue'

const PhoneInput = defineAsyncComponent(() => import('./PhoneInput.vue'))
const AddressInput = defineAsyncComponent(() => import('./GoogleAddressAutocomplete.vue'))

const wrp =
  (
    tag: string,
    blockName: string,
    root: Component = InputElement
  ) =>
  (
    props: Record<string, unknown>,
    ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
  ): VNode => {
    return h(
      root,
      { ...props, ...ctx.attrs, innerElement: tag, blockName },
      ctx.slots
    )
  }

export const CSelect = wrp('vue-multiselect', 'control-select', BaseSelect)

export function hoc(WrappedComponent: Component, params: Record<string, unknown>): VNode {
  return h(WrappedComponent, {
    ...params,
  })
}

export const CCheckbox = hoc(Checker, { b: 'control-checkbox' })
export const CRadio = hoc(Checker, { b: 'control-radio' })
export const CInput = wrp('input', 'control-input')
export const CTextarea = wrp('textarea', 'control-textarea')
export const CPhone = wrp('phone-input', 'control-phone')

const ControlAddressInput = hoc(AddressInput, {
  blockName: 'd-control-input',
})


const dt =
  (tag: string, root = CDescriptor) =>
  (
    props: Record<string, unknown>,
    ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
  ): VNode =>
    h(root, { ...props, ...ctx.attrs, component: tag }, ctx.slots)

const DSelect = dt('ControlSelect')
const DInput = dt('ControlInput')
const DTextarea = dt('ControlTextarea')
const DPhone = dt('ControlPhone')
const DAddressInput = dt('AddressInput')
const DDatePicker = dt('ControlDatePicker')

const install = (app: App<Element>): void => {
  const prefix = 'DControl'
  app
    .component(`${prefix}Input`, Controller)
    .component(`${prefix}Label`, InputLabel)
    .component(`${prefix}Textarea`, InputTextArea)
    .component(`${prefix}SimpleInput`, SimpleInput)
    .component(`${prefix}Multiselect`, Multiselect)
    .component(`${prefix}SingleSelect`, Multiselect)
    .component(`${prefix}Recaptcha`, Recaptcha)
    .component(`${prefix}Image`, Image)
    .component(`${prefix}File`, File)
    .component(`${prefix}Phone`, PhoneInput)
    .component(`${prefix}OtpInput`, OtpInput)
    .component(`${prefix}Flatpickr`, Flatpickr)
    .component(`${prefix}Switch`, Switch)
    .component(`${prefix}AddressInput`, ControlAddressInput)
    .component(`${prefix}Editor`, Editor)
    .component(`${prefix}Inliner`, Inliner)
    .component(`${prefix}MultilangInput`, MultilangInput)
    .component(`${prefix}MazSelectInput`, MazSelectInput)
    .component('MazSelect', MazSelect)
    .component('AddressInput', AddressInput)
    .component('SocialNetwork', SocialNetwork)
    .component('FakeInput', FakeInput)
    .component('DummyTag', DummyTag)
    .component('CityMapAutocomplete', CityMapAutocomplete)
    .component('MultiselectSelections', MultiselectSelections)

    .component('ControlButton', CButton)
    .component('ControlLabel', CLabel)
    .component('ControlInput', CInput)
    .component('ControlTextarea', CTextarea)
    .component('ControlCheckbox', CCheckbox)
    .component('ControlRadio', CRadio)
    .component('ControlSelect', CSelect)
    .component('ControlPhone', CPhone)
    .component('ControlDatePicker', CDatePicker)

    .component('DInput', DInput)
    .component('DTextarea', DTextarea)
    .component('DSelect', DSelect)
    .component('DPhone', DPhone)
    .component('DAddressInput', DAddressInput)
    .component('DDatePicker', DDatePicker)
}

export default {
  install,
}
