import { App } from 'vue'

import AuthComponents from './components'

export default {
  install(app: App<Element>): void {
    app
      .use(AuthComponents)
  }
}
