
import { App, defineAsyncComponent } from 'vue'

const Catalog = defineAsyncComponent(() => import('./Catalog.vue'))

const install = (app: App<Element>, prefix = 'InfoCenter'): void => {
  app
    .component(`${prefix}Catalog`, Catalog)
}

export default {
  install,
}
