export const getConfig = () => {
  console.log(process.env, process.env.FIREBASE_CREDENTIAL);
  return {
    apiKey: "AIzaSyDGechtInvUYJAdZEIX-HhDLKkys0MpNl0",
    projectId: "med-online-com",
    storageBucket: "med-online-com.firebasestorage.app",
    messagingSenderId: "661781358359",
    appId: "1:661781358359:web:1c03f0fb6fa209ecf57952",
    measurementId: "G-ET7Z7G5XH1",
    authDomain: window.location.host,
  }
}
