<template>
  <svg width="375" height="50" viewBox="0 0 375 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M302.257 21.7037C302.257 9.7171 292.54 0 280.554 0H95.1354C83.1487 0 73.4316 9.7171 73.4316 21.7037C73.4316 33.6904 63.7145 43.4075 51.7279 43.4075L3.10054 43.4075C1.38816 43.4075 0 44.7956 0 46.508C0 48.2204 1.38814 49.6085 3.10052 49.6085H371.899C373.612 49.6085 375 48.2204 375 46.508C375 44.7956 373.612 43.4075 371.899 43.4075L323.961 43.4075C311.974 43.4075 302.257 33.6904 302.257 21.7037Z" fill="url(#paint0_linear_3874_25400)"/>
    <defs>
      <linearGradient id="paint0_linear_3874_25400" x1="453.394" y1="-58.9296" x2="459.602" y2="50.8513" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>