import { ref } from 'vue'


export function useCropperControl() {
  const cropper = ref()

  function flip(x: number, y: number) {
    if (cropper.value.rotate % 180 !== 0) {
      cropper.value.flip(!x, !y)
    } else {
      cropper.value.flip(x, y)
    }
  }

  function rotate(angle: number) {
    cropper.value.rotate(angle)
  }

  function download() {
    const src = result().canvas.toDataURL()
    const newTab = window.open()
    if (newTab) {
      newTab.document.body.innerHTML = `<img src="${src}"></img>`
    }
  }

  function result() {
    return cropper.value.getResult()
  }

  return {
    cropper,
    flip,
    rotate,
    download,
    result,
  }
}
