
import { App, defineAsyncComponent } from 'vue'

const Catalog = defineAsyncComponent(() => import('./Catalog.vue'))
const TreatmentCatalog = defineAsyncComponent(() => import('./TreatmentCatalog.vue'))
const TreatmentCountries = defineAsyncComponent(() => import('./TreatmentCountries.vue'))

const install = (app: App<Element>, prefix = 'Illness'): void => {
  app
    .component(`${prefix}Catalog`, Catalog)
    .component(`${prefix}TreatmentCatalog`, TreatmentCatalog)
    .component(`${prefix}TreatmentCountries`, TreatmentCountries)
}

export default {
  install,
}
