import { RouterView, type RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

import { FAVORITES_TABS } from './const'
import { defineAsyncComponent } from 'vue'

const FAVORITES_PREFIX = 'cabinet:favorites'
const p = (name: string) => FAVORITES_PREFIX + ':' + name

export const FAVORITES_LIST_VIEW_NAME = p('list')
const pTabs = (name: string) => FAVORITES_LIST_VIEW_NAME + ':' + name

const View = () => import('./views/View.vue')
const TabView = () => import('./views/TabView.vue')

const BASE_MODEL_NAME = 'favorites/'

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  const { app } = options
  app.config.globalProperties.$router_favorites_view_name = FAVORITES_LIST_VIEW_NAME

  const tabsRoutes = Object.values(FAVORITES_TABS).map(
    ({ value, endpoint, limit, listComponent, emptyResult }) => {
      return route(`${value}/`, TabView, pTabs(value), {
        meta: {
          title: () => $t('Favorites'),
          props: {
            endpoint,
            limit,
            listComponent,
            emptyResult,
          },
        },
      })
    }
  )

  return [
    route('list/', View, FAVORITES_LIST_VIEW_NAME, {
      children: tabsRoutes,
      meta: {
        title: () => $t('Favorites'),
      },
      redirect: { name: tabsRoutes[0].name },
    }),
    {
      path: '',
      redirect: { name: tabsRoutes[0].name },
    },
  ]
}

export { BASE_MODEL_NAME }
