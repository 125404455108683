import { App } from 'vue'
import { createI18n } from "vue-i18n"
import { CURRENT_LOCALE } from './const'

declare global {
  interface Window {
    language: string
    django: {
      catalog: {
        [key: string]: string
      }
    }
  }
}

const messages = {
  [window.language]: window.django && window.django.catalog || {},
}

type compileMessages = {
  locale: string,
  messages: typeof messages
}

export const compileMessages = (): compileMessages => ({
  locale: window.language,
  messages,
})

// Data-time formats

const NUMERIC = 'numeric' as const
const LONG = 'long' as const
const SHORT = 'short' as const
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
}
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit' as const,
}
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
}
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
}
console.log('CURRENT_LOCALE', CURRENT_LOCALE);
const datetimeFormats = {
  [CURRENT_LOCALE]: {
    short: SHORT_DATE_CONFIG,
    medium: { year: NUMERIC, month: LONG, day: NUMERIC },
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    daymonth: { day: NUMERIC, month: SHORT },
    year: { year: NUMERIC },
    datetime: DATETIME_CONFIG,
  },
  ['uk']: {
    short: SHORT_DATE_CONFIG,
    medium: { year: NUMERIC, month: LONG, day: NUMERIC },
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    daymonth: { day: NUMERIC, month: SHORT },
    year: { year: NUMERIC },
    datetime: DATETIME_CONFIG,
  },
}

const i18nOptions = {
  ...compileMessages(),
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  legacy: false,
  datetimeFormats,
}

export const i18n = createI18n(i18nOptions)

export default {
  install(app: App<Element>): void {
    app.use(i18n)
  }
}
