<svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/password-check">
<g id="password-check">
<path id="Vector" d="M22.9584 41.4922H15.625C14.3334 41.4922 13.1875 41.4505 12.1667 41.3047C6.68754 40.7005 5.20837 38.1172 5.20837 31.0755V20.6589C5.20837 13.6172 6.68754 11.0339 12.1667 10.4297C13.1875 10.2839 14.3334 10.2422 15.625 10.2422H22.8334" stroke="url(#paint0_linear_1766_13587)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M31.2916 10.2422H34.375C35.6666 10.2422 36.8125 10.2839 37.8333 10.4297C43.3125 11.0339 44.7916 13.6172 44.7916 20.6589V31.0755C44.7916 38.1172 43.3125 40.7005 37.8333 41.3047C36.8125 41.4505 35.6666 41.4922 34.375 41.4922H31.2916" stroke="url(#paint1_linear_1766_13587)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_3" d="M31.25 5.03516V46.7018" stroke="url(#paint2_linear_1766_13587)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_4" d="M23.1135 25.8659H23.1322" stroke="url(#paint3_linear_1766_13587)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_5" d="M14.7802 25.8659H14.7989" stroke="url(#paint4_linear_1766_13587)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_1766_13587" x1="26.669" y1="-26.8794" x2="60.0002" y2="17.4144" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
<linearGradient id="paint1_linear_1766_13587" x1="47.6138" y1="-26.8794" x2="82.2996" y2="8.17797" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
<linearGradient id="paint2_linear_1766_13587" x1="32.4591" y1="-44.4603" x2="37.6367" y2="-44.1696" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
<linearGradient id="paint3_linear_1766_13587" x1="24.5971" y1="22.3494" x2="26.2709" y2="26.2576" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
<linearGradient id="paint4_linear_1766_13587" x1="16.2638" y1="22.3494" x2="17.9376" y2="26.2576" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
</defs>
</svg>