
import { App, defineAsyncComponent } from 'vue'

const FeedbackForm = defineAsyncComponent(() => import('./Feedback.vue'))
const SubscribeForm = defineAsyncComponent(() => import('./Subscribe.vue'))
const ImpressionForm = defineAsyncComponent(() => import('./Impression.vue'))
const RatingForm = defineAsyncComponent(() => import('./Rating.vue'))

const install = (app: App<Element>, postfix = 'Form'): void => {
  app
    .component(`Feedback${postfix}`, FeedbackForm)
    .component(`Subscribe${postfix}`, SubscribeForm)
    .component(`Impression${postfix}`, ImpressionForm)
    .component(`Rating${postfix}`, RatingForm)
}

export default {
  install,
}
