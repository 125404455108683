import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "multiselect__single" }
const _hoisted_3 = { class: "i-count i-count--variant_1" }
const _hoisted_4 = { class: "i-count__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (Array.isArray($props.values) && $props.values.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString($props.values.map(el => el[$props.optionTitle]).join(', ')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString($props.values.length), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}