import { defineStore } from 'pinia'
import { ref, computed, Ref } from 'vue'
import { accountApi } from '@services/account.service'
import { User as UserClass } from './user/user'
import { medicalFacilityBranchesResource } from '@/services/facility.service'
import { ProfileTypeChoices } from '@m/cabinet/modules/Profile/const'

declare global {
  interface Window {
    isAuthenticated: string | number
  }
}

export const useAuthStore = defineStore('authStore', () => {
  const loading: Ref<boolean> = ref(false)
  const medicalFacilityId: Ref<string> = ref(null)
  const doctorId: Ref<string> = ref(null)
  const branchId: Ref<string> = ref(null)
  const medicalFacilitiesCount: Ref<number> = ref(0)
  const user = ref(new UserClass({}, false))

  const isAuthenticated = computed(() => user.value.isAuthenticated())
  const isDetermined = computed(() => user.value.determined)
  const profileType = computed(() => user.value?.info?.profileType)
  const isMedicalFacility = computed(() => user.value?.info.profileType?.value == ProfileTypeChoices.B3)
  const isPrivateDoctor = computed(() => user.value?.info.profileType?.value == ProfileTypeChoices.B2)
  const isMedicalFacilityDoctor = computed(() => user.value?.info.profileType?.value == ProfileTypeChoices.B1)
  const isBranch = computed(() => user.value?.info.profileType?.value == ProfileTypeChoices.B4)

  function setUser(newUser: UserClass): void {
    user.value = newUser
    if (user.value?.info) {
      const { medicalFacilityIds: [firstMedicalFacilityId], doctorIds: [firstDoctorId], branchIds: [firstBranchId] } = user.value.info
      medicalFacilitiesCount.value = user.value.info.medicalFacilityIds.length
      medicalFacilityId.value = firstMedicalFacilityId
      doctorId.value = firstDoctorId
      branchId.value = firstBranchId
    }
  }
  function updateUser(data: { [key: string]: unknown }): void {
    user.value = new UserClass({ ...user.value.info, ...data })
  }
  function setLoading(value: boolean): void {
    loading.value = value
  }
  function initialReceive() {
    if (user.value.determined) {
      return null
    }
    return lockedReceive()
  }
  function lockedReceive(): Promise<void> | null {
    if (loading.value) {
      return null
    }

    return receive()
  }
  async function receive(): Promise<void> {
    setLoading(true)

    return await accountApi.retrieve.execute()
      .then(({ item = {} }) => {
        setUser(new UserClass(item))
      })
      .catch((error: Response) => {
        setUser(new UserClass({}))
        console.warn(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function patch(payload: Record<string, any>) {
    return accountApi.update.execute({}, payload)
      .then(({ data: { item = {} } = {} }) => {
        updateUser(item)
        return item
      })
  }

  return {
    loading,
    user,
    profileType,
    isAuthenticated,
    isDetermined,
    medicalFacilityId,
    doctorId,
    branchId,
    medicalFacilitiesCount,
    isMedicalFacility,
    isPrivateDoctor,
    isMedicalFacilityDoctor,
    isBranch,
    setUser,
    updateUser,
    setLoading,
    initialReceive,
    lockedReceive,
    receive,
    patch,
  }
})
