import { App, defineAsyncComponent } from 'vue'

const RelatedMaterials = defineAsyncComponent(() => import('./RelatedMaterials.vue'))

const install = (app: App<Element>, prefix = 'Slider'): void => {
  app
    .component(`${prefix}RelatedMaterials`, RelatedMaterials)
}

export default {
  install,
}
