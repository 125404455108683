import { r, createSender, createRemover, createSimpleSender } from '@/plugins/resource'

const MODEL = 'auth'
const MODEL_FIREBASE = 'firebase/authentication'
const m = postfix => `${MODEL}/${postfix}`

const TOKEN_MODEL = m('token')
const PASSWORD_MODEL = m('password')

const REFRESH_TOKEN_LINK = '/refresh/'
const OBTAIN_LINK = '/obtain/'
const LOGOUT_LINK = '/logout/'
const PASSWORD_RESET_LINK = '/reset/'
const PASSWORD_SET_LINK = '/set-new/'
const PASSWORD_CHANGE_LINK = '/change/'

const TWO_FACTOR_REQUEST_LINK = '2factor/request-confirmation/'
const TWO_FACTOR_CONFIRM_LINK = '2factor/confirm/'

const FIREBASE_AUTH_LINK = '/auth/'
const FIREBASE_LIST_LINK = '/list/'
const FIREBASE_CREATE_CONNECT_LINK = '/create/'
const FIREBASE_DELETE_CONNECT_LINK = '/delete/{id}/'


export const refreshToken = r(REFRESH_TOKEN_LINK, TOKEN_MODEL, createSender)
export const authResource = r(OBTAIN_LINK, TOKEN_MODEL, createSender)

export const passwordResetResource = r(PASSWORD_RESET_LINK, PASSWORD_MODEL, createSender)
export const passwordChange = r(PASSWORD_CHANGE_LINK, PASSWORD_MODEL, createSender)
export const newPasswordSet = r(PASSWORD_SET_LINK, PASSWORD_MODEL, createSender)

export const logoutResource = r(LOGOUT_LINK, MODEL, createSimpleSender)

export const twoFactorRequestResource = r(TWO_FACTOR_REQUEST_LINK, '', createSender)
export const twoFactorConfirmResource = r(TWO_FACTOR_CONFIRM_LINK, '', createSender)

export const firebaseAuthRequest = r(
  FIREBASE_AUTH_LINK, MODEL_FIREBASE, createSender
)
export const firebaseListRequest = r(
  FIREBASE_LIST_LINK, MODEL_FIREBASE
)
export const firebaseCreateConnectRequest = r(
  FIREBASE_CREATE_CONNECT_LINK, MODEL_FIREBASE, createSender
)
export const firebaseDeleteConnectRequest = r(
  FIREBASE_DELETE_CONNECT_LINK, MODEL_FIREBASE, createRemover
)
