import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "required-mark"
}
const _hoisted_4 = {
  key: 1,
  class: "c-label-hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return ($props.inputLabel)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["control-descriptor__label", { 'control-descriptor__label--static': $props.staticLabel, 'control-descriptor__label--float': !$props.staticLabel, 'control-descriptor__label--active': !$props.staticLabel && $setup.isValue, [`control-descriptor__label--styling_${$setup.props.styling}`]: true }]),
        title: $props.inputLabel
      }, [
        _createElementVNode("label", {
          class: "control-descriptor__label-text",
          for: $props.id
        }, _toDisplayString($props.inputLabel), 9, _hoisted_2),
        ($props.isRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
          : _createCommentVNode("", true),
        ($props.hint)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_AppIcon, {
                name: "i-info-circle",
                size: "sm"
              })
            ])), [
              [_directive_tooltip, { content: $props.hint, distance: 0 }]
            ])
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}