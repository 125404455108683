type UserIdentifier = string | number
export interface UserInfo {
  id?: UserIdentifier
  pk?: UserIdentifier
  auth?: UserIdentifier
  name?: string
  phone?: string
  email?: string
  image?: string
  role?: {
    value?: string
  }
  [key: string]: unknown
  medicalFacilityIds?: string[]
  doctorIds?: string[]
  branchIds?: string[],
  profileType?: {
    label: string,
    value: string,
  }
}

export class UserBase {
  determined = false;

  info: UserInfo = {}

  isAuthenticated(): boolean {
    return this.determined && this.info && !!(this.info.id || this.info.pk || this.info.auth || !!this.info?.user) || false;
  }
}
