import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "control-descriptor__element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlLabel = _resolveComponent("ControlLabel")!
  const _component_VeeField = _resolveComponent("VeeField")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control-descriptor", { 'error': $props.errors[$props.name], 'success': $props.isSuccess, 'has-prepend': _ctx.$slots.prepend || _ctx.$attrs.startIcon || _ctx.$attrs['start-icon'] }])
  }, [
    _createVNode(_component_VeeField, _mergeProps({
      as: "",
      name: $props.name,
      rules: $props.rules,
      id: $props.id ? $props.id : $props.name
    }, _ctx.$attrs), {
      default: _withCtx(({ field, errorMessage, meta }) => [
        _renderSlot(_ctx.$slots, "c-label", {}, () => [
          _createVNode(_component_ControlLabel, {
            styling: $props.labelStyling || 'default',
            "static-label": $props.staticLabel,
            "input-label": $props.inputLabel,
            "is-required": $setup.isRequired,
            hint: $props.hint,
            id: $props.id ? $props.id : $props.name,
            value: field.value
          }, null, 8, ["styling", "static-label", "input-label", "is-required", "hint", "id", "value"])
        ]),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.inputComponent), _mergeProps({
            invalid: !!errorMessage
          }, 'ControlSelect' === $setup.inputComponent ? { ..._ctx.$props, ..._ctx.$attrs } : { ...field, ..._ctx.$props, ..._ctx.$attrs }, {
            value: field.value,
            type: $props.type,
            id: $props.id ? $props.id : $props.name,
            "onUpdate:isValid": _cache[0] || (_cache[0] = ($event: any) => ($setup.isValid = $event))
          }), _createSlots({ _: 2 }, [
            _renderList(_ctx.$slots, (_, name) => {
              return {
                name: name,
                fn: _withCtx((bound) => [
                  _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(bound || {})))
                ])
              }
            })
          ]), 1040, ["invalid", "value", "type", "id"]))
        ]),
        errorMessage
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["control-descriptor__float-errors", { 'with-static-label': $props.staticLabel && $props.inputLabel}])
            }, _toDisplayString(errorMessage), 3))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 16, ["name", "rules", "id"])
  ], 2))
}