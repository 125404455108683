import { App } from 'vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';

/* eslint-disable */
export default {
  install(app: App<Element>) {
    app.component("flatPickr", flatPickr)
  }
}
