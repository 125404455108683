<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <g clip-path="url(#clip0_3510_23379)">
    <path d="M16.5 7.5C16.5 7.0275 16.275 6.585 15.9 6.3L9.9 1.8C9.64036 1.60527 9.32456 1.5 9 1.5C8.67544 1.5 8.35964 1.60527 8.1 1.8L2.1 6.3C1.91371 6.43972 1.7625 6.6209 1.65836 6.82918C1.55422 7.03746 1.5 7.26713 1.5 7.5M16.5 7.5V15C16.5 15.3978 16.342 15.7794 16.0607 16.0607C15.7794 16.342 15.3978 16.5 15 16.5H3C2.60218 16.5 2.22064 16.342 1.93934 16.0607C1.65804 15.7794 1.5 15.3978 1.5 15V7.5M16.5 7.5L9.7725 11.775C9.54095 11.9201 9.27324 11.997 9 11.997C8.72676 11.997 8.45905 11.9201 8.2275 11.775L1.5 7.5" stroke="#386280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_3510_23379">
      <rect width="18" height="18" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>