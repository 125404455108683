import { compose } from 'ramda'
import { prefixLanguage } from '@utils/urls'

export const DETAIL_URL: string = '/detail/{id}/'
export const CREATE_URL: string = '/create/'

export const API_BASE_URL = '/api/v1/'
export const prefixAPI = (url: string | null, model = ''): string => 
  API_BASE_URL + model + url


const paginationKeys = ['limit', 'offset']
export const queryPostfix = (s: string, p: string[] = []): string => {
  const q = [...paginationKeys, ...p].filter(i => i).join(',')
  return `${s}{?${q}}`
}

export const urlConstructor = (url: string, postfix: string[]): string => {
  const r = compose(prefixAPI, queryPostfix)
  return r(url, postfix)
}