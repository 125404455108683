import { type RouteLocationNormalizedLoaded, type RouteRecordNormalized } from 'vue-router'

type AccessChecker = {
  onHasAccess: (vm: unknown, params: Record<string, unknown>) => Promise<boolean>
}

type RedirectConfig = {
  name: string,
}

type AccessConfig = {
  checker: AccessChecker,
  resolver?: (route: RouteLocationNormalizedLoaded) => boolean,
  next?: boolean,
  redirect?: RedirectConfig,
}

function isAccessChecker(obj: any): obj is AccessChecker {
  return obj && typeof obj.onHasAccess === 'function'
}

function isAccessConfig(obj: any): obj is AccessConfig {
  return obj && isAccessChecker(obj.checker)
}

export function collectRoutePermissionCheckers(
  route: RouteLocationNormalizedLoaded
): [AccessChecker, RouteRecordNormalized][] {
  return route.matched.reduce((acc, r) => {
    const checker = r.meta.accessConfig && r.meta.accessConfig.checker

    if (isAccessChecker(checker)) {
      acc.push([checker, r])
    }

    return acc
  }, [] as [AccessChecker, RouteRecordNormalized][])
}

export function routeProhibitionResolver(
  route: RouteLocationNormalizedLoaded
): false | { name: string, params: { pathMatch: string[] }, query: any, hash: string } {
  const accessConfig = route.meta.accessConfig
  if (!isAccessConfig(accessConfig)) {
    return false
  }

  const { resolver, next, redirect } = accessConfig

  if (resolver) {
    return resolver(route) ? false : { name: '', params: { pathMatch: [] }, query: {}, hash: '' }
  }

  if (next) {
    return false
  }

  if (redirect && redirect.name) {
    return {
      name: redirect.name,
      params: { pathMatch: route.path.split('/').slice(1).slice(0, -1) },
      query: route.query,
      hash: route.hash,
    }
  }

  return false
}

export function onHasRouteAccess(
  r: RouteLocationNormalizedLoaded,
  vm: unknown,
  parameters: Record<string, unknown> = {}
): Promise<boolean> {
  const checkers = collectRoutePermissionCheckers(r)

  if (!(checkers && checkers.length)) {
    return Promise.resolve(true)
  }

  const executors = checkers.map(([checker, route]) => {

    checker
      .onHasAccess(vm, { route, ...parameters })
      .catch((e) => {
        console.log(e)
        throw route
      })
      .then(() => true)
  }
  )

  return Promise.all(executors).then(() => true).catch(() => false)
}

export function resolveRouteNext(
  r: RouteLocationNormalizedLoaded,
  vm: unknown,
  parameters: Record<string, unknown> = {}
): Promise<boolean | { name: string, params: { pathMatch: string[] }, query: any, hash: string }> {
  return onHasRouteAccess(r, vm, parameters).catch((route) => {
    return Promise.resolve(routeProhibitionResolver(route))
  })
}

export const accessGuard =
  (parameters: Record<string, unknown> = {}) =>
  (
    to: RouteLocationNormalizedLoaded,
    from: RouteLocationNormalizedLoaded,
    next: (result: boolean | { name: string, params: { pathMatch: string[] }, query: any, hash: string }) => void
  ): void => {
    resolveRouteNext(to, null, parameters).then((result) => {
      if (Array.isArray(result)) {
        const [res] = result
        return next(res)
      }
      return next(result)
    })
  }

export const accessConfig = (
  checker: AccessChecker,
  redirect: RedirectConfig = { name: '' },
  options: Record<string, unknown> = {}
): AccessConfig => ({
  checker,
  redirect,
  ...options,
})

export function accessMeta(checker: AccessChecker, redirect: RedirectConfig = { name: '' }, options: Record<string, unknown> = {}): { accessConfig: AccessConfig } {
  return { accessConfig: accessConfig(checker, redirect, options) }
}