import { RouteRecordRaw } from 'vue-router'
import { prefixLanguage } from '@utils/urls';
import type { Options } from '@/router'
import { prefixRoutes } from '@router/utils'

import { createRoutes as createCabinetRoutes } from '@m/cabinet/router'

export default function createInitialRoutes(options: Options): RouteRecordRaw[] {
  return [
    prefixRoutes(prefixLanguage('/cabinet/'), createCabinetRoutes(options)),
    // { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: { path: '/404/' } },
  ]
}
