<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<g clip-path="url(#clip0_2730_21961)">
<path d="M3.33203 15.1673H12.6654M3.33203 1.83398H12.6654M11.332 15.1673V12.386C11.332 12.0324 11.1914 11.6933 10.9414 11.4433L7.9987 8.50065M7.9987 8.50065L5.05603 11.4433C4.80597 11.6933 4.66544 12.0324 4.66536 12.386V15.1673M7.9987 8.50065L5.05603 5.55798C4.80597 5.30799 4.66544 4.96891 4.66536 4.61532V1.83398M7.9987 8.50065L10.9414 5.55798C11.1914 5.30799 11.332 4.96891 11.332 4.61532V1.83398" stroke="#B81ED1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2730_21961">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
</template>