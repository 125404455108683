import { ref, unref, ComputedRef, computed, Ref } from 'vue'

export function useLoadingState(): { 
  state: Ref<number>,
  loading: ComputedRef<boolean>,
  load: <T extends Promise<any>>(promise: T) => T
} {
  const state = ref(0)
  const loading = computed(() => state.value > 0)
  const load = function <T extends Promise<any>>(promise: T): T {
    state.value += 1
    promise.finally(() => {
      state.value -= 1
    })

    return promise
  }

  return { state, loading, load }
}

export type LoadingState = ReturnType<typeof useLoadingState>

export function mergeLoadingStates(states: { loading: Ref<boolean> | boolean }[]): ComputedRef<boolean> {
  const loading = computed(() => states.reduce(
    (acc, state) => acc || unref(state.loading),
    false
  ))

  return loading
}
