import { App, defineAsyncComponent } from 'vue'

const Input = defineAsyncComponent(() => import('@/modules/search/components/Input.vue'))
const Tabs = defineAsyncComponent(() => import('@/modules/search/components/Tabs.vue'))
const View = defineAsyncComponent(() => import('@/modules/search/components/View.vue'))

export default {
  install(app: App<Element>, prefix='Search'): void {
    app
      .component(`${prefix}Input`, Input)
      .component(`${prefix}Tabs`, Tabs)
      .component(`${prefix}View`, View)
  }
}
