import { App, defineAsyncComponent } from 'vue'

const Catalog = defineAsyncComponent(() => import('./Catalog.vue'))
const CatalogDoctor = defineAsyncComponent(() => import('./CatalogDoctor.vue'))

const install = (app: App<Element>, prefix = 'Service'): void => {
  app
    .component(`${prefix}Catalog`, Catalog)
    .component(`${prefix}CatalogDoctor`, CatalogDoctor)
}

export default {
  install,
}
