<template>
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
  <path d="M38.75 21.9993V36.5827C38.75 37.6877 38.311 38.7476 37.5296 39.529C36.7482 40.3104 35.6884 40.7493 34.5833 40.7493H5.41667C4.3116 40.7493 3.25179 40.3104 2.47039 39.529C1.68899 38.7476 1.25 37.6877 1.25 36.5827V7.41602C1.25 6.31095 1.68899 5.25114 2.47039 4.46974C3.25179 3.68834 4.3116 3.24935 5.41667 3.24935H20M28.3333 7.41602H40.8333M34.5833 1.16602V13.666M38.75 28.2493L32.3208 21.8202C31.5395 21.0391 30.4798 20.6002 29.375 20.6002C28.2701 20.6002 27.2105 21.0391 26.4292 21.8202L7.5 40.7493M17.9167 15.7493C17.9167 18.0505 16.0512 19.916 13.75 19.916C11.4488 19.916 9.58333 18.0505 9.58333 15.7493C9.58333 13.4482 11.4488 11.5827 13.75 11.5827C16.0512 11.5827 17.9167 13.4482 17.9167 15.7493Z" stroke="url(#paint0_linear_1416_12103)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_1416_12103" x1="49.1083" y1="-45.8547" x2="80.8664" y2="28.4469" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
  </defs>
</svg>
</template>