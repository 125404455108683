import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent($props.disabled && 'a' == $props.tag ? 'div' : $props.tag), _mergeProps({ class: $setup.blockClasses }, { 'aria-disabled': $props.disabled, 'readonly': $props.readonly, 'disabled': $props.disabled }), {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass([`${$props.blockName}__body`])
      }, [
        _renderSlot(_ctx.$slots, "prepend"),
        ($props.startIcon || _ctx.$slots.startIcon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_prepend`])
            }, [
              _renderSlot(_ctx.$slots, "startIcon", {}, () => [
                _createVNode(_component_AppIcon, {
                  name: $props.startIcon,
                  size: $props.iconSize
                }, null, 8, ["name", "size"])
              ])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--primary`])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        ($props.endIcon || _ctx.$slots.endIcon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_append`])
            }, [
              _renderSlot(_ctx.$slots, "endIcon", {}, () => [
                _createVNode(_component_AppIcon, {
                  name: $props.endIcon,
                  size: $props.iconSize
                }, null, 8, ["name", "size"])
              ])
            ], 2))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "append")
      ], 2)
    ]),
    _: 3
  }, 16, ["class"]))
}