import { App, defineAsyncComponent } from 'vue'

const Toggler = defineAsyncComponent(() => import('./Toggler.vue'))
const Counter = defineAsyncComponent(() => import('./Counter.vue'))

const install = (app: App<Element>, prefix = 'Favorite'): void => {
  app
    .component(`${prefix}Toggler`, Toggler)
    .component(`${prefix}Counter`, Counter)
}

export default {
  install,
}
