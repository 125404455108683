import { type App } from 'vue'

export * from './routing'
import { default as RouteAvailable } from './RouteAvailable'

export default {
  install(
    app: App<Element>,
    { availableName = 'route-available' } = {}
  ): void {
    app.component(availableName, RouteAvailable)
  },
}

export { RouteAvailable }
