<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 19.5H21" stroke="url(#paint0_linear_3924_6127)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 12.5H21" stroke="url(#paint1_linear_3924_6127)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 5.5H21" stroke="url(#paint2_linear_3924_6127)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 5.5L4 6.5L7 3.5" stroke="url(#paint3_linear_3924_6127)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 12.5L4 13.5L7 10.5" stroke="url(#paint4_linear_3924_6127)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 19.5L4 20.5L7 17.5" stroke="url(#paint5_linear_3924_6127)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_3924_6127" x1="23.0905" y1="18.3121" x2="23.1852" y2="20.5281" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3924_6127" x1="23.0905" y1="11.3121" x2="23.1852" y2="13.5281" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint2_linear_3924_6127" x1="23.0905" y1="4.31211" x2="23.1852" y2="6.52808" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint3_linear_3924_6127" x1="7.83621" y1="-0.0636757" x2="9.77224" y2="5.97575" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint4_linear_3924_6127" x1="7.83621" y1="6.93632" x2="9.77224" y2="12.9758" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint5_linear_3924_6127" x1="7.83621" y1="13.9363" x2="9.77224" y2="19.9758" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>