import { UserBase, UserInfo } from './base';

export class User extends UserBase {
  constructor(info: UserInfo, determined = true) {
    super();

    this.determined = determined;
    this.info = info || this.info;
  }
}
