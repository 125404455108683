export const CODE_VARIANTS_LENGTH = [
  8,
  10,
  12,
]

export const MOBILEREG = /^[+()-\d]+$/

export const EMAILREG =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


export const URLREG = /^(https?|chrome):\/\/[^\s$.?#].[^\s].+\?*={click_id}.*$/