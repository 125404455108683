import { App, defineAsyncComponent } from 'vue'

const Doctors = defineAsyncComponent(() => import('./Doctors.vue'))
const Facilities = defineAsyncComponent(() => import('./Facilities.vue'))

const install = (app: App<Element>, prefix = 'SearchFilter'): void => {
  app
    .component(`${prefix}Doctors`, Doctors)
    .component(`${prefix}Facilities`, Facilities)
}

export default {
  install,
}
