export const EXTENSIONS_MAP = {
  'image/png': '.png',
  'image/jpeg': '.jpg',
  'image/gif': '.gif',
}

export const APPLICATION_PDF = 'application/pdf'
export const APPLICATION_WORD = 'application/msword'
export const APPLICATION_WORD_X = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const APPLICATION_POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'

export const DOCS_EXTENSIONS_MAP = {
  [APPLICATION_PDF]: '.pdf',
  [APPLICATION_WORD_X]: '.docx',
  [APPLICATION_WORD]: '.doc',
  [APPLICATION_POWERPOINT]: '.pptx',
};
