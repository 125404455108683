import type { App } from 'vue'
import {
  Router,
  RouteRecordRaw,
  createWebHistory,
  createRouter as typeCreateRouter,
  RouteLocation,
  RouteLocationNormalizedLoaded,
} from 'vue-router'
import { Pinia } from 'pinia'
import { accessGuard } from '@/packages/vue-router-permissions';
import createInitialRoutes from '@/router/routes'

export type Options = {
  app: App<Element>,
  createInstance: typeof typeCreateRouter,
  createWebHistory: typeof createWebHistory,
  pinia: Pinia,
}
import 'vue-router'

declare module 'vue-router' {
  interface RouteMeta {
    accessConfig?: {
      checker: (...arg: any[]) => boolean
    }
  }
}

export function createRoutes(options: Options): RouteRecordRaw[] {
  return [
    ...createInitialRoutes(options),
  ];
}
type _ScrollPositionNormalized = {
  behavior?: ScrollOptions['behavior']
  left: number
  top: number
}
export function scrollBehavior(
  to: RouteLocation,
  from: RouteLocationNormalizedLoaded,
  savedPosition: null | _ScrollPositionNormalized
): false | void | { top: number, left: number } | { el: string | Element } {
  if (savedPosition) {
    return savedPosition;
  }
  return {
    top: window.pageXOffset,
    left: window.pageYOffset,
  };
}

export default function createAppRouter(options: Options): Router {
  const { createInstance, createWebHistory } = options;
  const router = createInstance({
    history: createWebHistory('/'),
    scrollBehavior,
    routes: createRoutes(options),
  });
  
  router.beforeEach(accessGuard(options));

  return router;
}
