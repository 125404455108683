import { computed, ref } from 'vue'
// import { notificationsApi } from '@/services/notifications.service'
import { useAuthStore } from '@m/auth/store'
import { sitesConfigsList } from '@/services/general.service'
import { defineStore } from 'pinia'
import { ProfileTypeChoices } from '@m/cabinet/modules/Profile/const'
import { notificationsStatsResource } from '@/services/notifications.service'
import { useCancelableRequest } from '@/composables/useCancelableRequest'

interface NotificationStats {
  flags: {
    pk: number
    count: number
  }[]
}

export const useUserStore = defineStore('userStore', () => {
  const authStore = useAuthStore()

  const defaultLanguage = ref(null)
  const country = ref(null)
  const countriesList = ref([])

  const notificationCounts = ref<NotificationStats>({
    flags: [],
  })

  const isMedicalFacility = ref(null)
  const isPrivateDoctor = ref(null)
  const isMedicalFacilityDoctor = ref(null)
  const isBranch = ref(null)

  const displayName = computed(() => {
    if (!authStore.user.isAuthenticated()) {
      return null
    }

    const {
      username,
      fullName,
      displayName: userDisplayName,
      firstName,
      lastName,
    } = authStore.user.info

    return userDisplayName || username || fullName || (firstName || '-') + ' ' + (lastName || '-')
  })

  const userInfo = computed(() => {
    if (!authStore.user.isAuthenticated()) {
      return {}
    }
    const { info } = authStore.user
    return info
  })

  function prioritizeDefaultLanguage(list, defaultId) {
    return list.sort((a, b) => {
        if (a.id === defaultId) return -1;
        if (b.id === defaultId) return 1;
        return 0;
    });
  }

  async function getLocalization() {
    const { items, current } = (await sitesConfigsList.execute())
    country.value = current
    country.value.languagesAvailable = prioritizeDefaultLanguage(country.value.languagesAvailable, current.languagesDefault)
    defaultLanguage.value = current.languagesDefault
    countriesList.value = items
  }

  const { load: loadNotificationCounts, result: _notificationsCount } = useCancelableRequest(notificationsStatsResource)
  
  // const notificationCounts = computed(() => {
  //   console.log('_notificationsCount', _notificationsCount);
  //   if (_notificationsCount) {
  //     return _notificationsCount.value?.items
  //   }
  //   return []
  // })
  async function getNotificationCounts() {
    return await loadNotificationCounts()
      .then(({ items }: { items: NotificationStats[] }) => {
        notificationCounts.value = items && items[0]
      })
  }

  return { 
    displayName, userInfo,
    country, countriesList, defaultLanguage,
    getLocalization,
    isMedicalFacility,
    isPrivateDoctor,
    isMedicalFacilityDoctor,
    isBranch,
    notificationCounts,
    getNotificationCounts,
  }
})
