import { App, defineAsyncComponent } from 'vue'

const Card = defineAsyncComponent(() => import('./Card.vue'))
const CardExtended = defineAsyncComponent(() => import('./CardExtended.vue'))
const Calendar = defineAsyncComponent(() => import('./Calendar.vue'))
const CatalogAppointment = defineAsyncComponent(() => import('./CatalogAppointment.vue'))
const Catalog = defineAsyncComponent(() => import('./Catalog.vue'))
const DoctorsMap = defineAsyncComponent(() => import('./Map.vue'))

const install = (app: App<Element>, prefix = 'Doctor'): void => {
  app
    .component(`${prefix}Card`, Card)
    .component(`${prefix}CardExtended`, CardExtended)
    .component(`${prefix}Calendar`, Calendar)
    .component(`${prefix}CatalogAppointment`, CatalogAppointment)
    .component(`${prefix}Catalog`, Catalog)
    .component(`${prefix}Map`, DoctorsMap)
}

export default {
  install,
}
