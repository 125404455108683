import Cookies from 'js-cookie';
import { headersMiddleware, bodyMiddleware } from '@aspectus/resource';

export const xCSRFMiddleware = headersMiddleware(parameters => ({
  ...parameters, 'X-CSRFToken': Cookies.get('csrftoken'),
}));

export const multipartRequestMiddleware = bodyMiddleware(
  body => Object.keys(body).reduce(
    (acc, x) => {
      const value = Array.isArray(body[x]) ? body[x] : [body[x]];
      value.forEach(v => acc.append(x, v));

      return acc;
    },
    new FormData()
  )
);
