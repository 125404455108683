<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M13 11.0008L21.2 2.80078" stroke="url(#paint0_linear_2673_4490)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21.9992 6.8V2H17.1992" stroke="url(#paint1_linear_2673_4490)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="url(#paint2_linear_2673_4490)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_2673_4490" x1="22.9142" y1="-6.93993" x2="29.4932" y2="8.45224" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2673_4490" x1="23.0027" y1="-3.70188" x2="26.8538" y2="5.30817" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2673_4490" x1="26.181" y1="-21.7578" x2="42.2272" y2="15.784" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
  </defs>
</svg>
</template>