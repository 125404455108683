import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const REVIEWS_PREFIX = 'cabinet:reviews'
const p = (name: string) => REVIEWS_PREFIX + ':' + name

export const REVIEWS_LIST_VIEW_NAME = p('list')
export const REVIEWS_VIEW_NAME = p('view')

const View = () => import('./views/View.vue')
const List = () => import('./views/List.vue')

const BASE_MODEL_NAME = 'reviews/'


export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global

  return [
    route('list/', List, REVIEWS_LIST_VIEW_NAME, {
      meta: {
        title: () => $t('Reviews'),
      }
    }),
    route(':id/', View, REVIEWS_VIEW_NAME, {
      meta: {
        title: () => $t('Reviews'),
        fallbackRouteName: REVIEWS_LIST_VIEW_NAME,
      },
    })
    
  ]
}

export { BASE_MODEL_NAME }