<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z" stroke="url(#paint0_linear_2498_20193)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.16021 14.56C4.74021 16.18 4.74021 18.82 7.16021 20.43C9.91021 22.27 14.4202 22.27 17.1702 20.43C19.5902 18.81 19.5902 16.17 17.1702 14.56C14.4302 12.73 9.92021 12.73 7.16021 14.56Z" stroke="url(#paint1_linear_2498_20193)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_2498_20193" x1="18.2964" y1="-8.5366" x2="25.4074" y2="8.11901" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2498_20193" x1="21.8367" y1="2.9449" x2="26.6569" y2="20.7846" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
  </defs>
</svg>
  </template>