import { ModifiersDefinition, applyModifiers } from './modifiers'

/**
 * State delimiters configuration object.
 */
export type BemStateConfig = {
  /** State prefix, like: 'is-'. */
  sp: string
  /** State value delimiter, like: '_'. */
  v: string
}

/**
 * State classes generator.
 *
 * **Example:**
 * ```js
 * import { state } from '@aspectus/bem';
 *
 * const STATE_CONFIG = { sp: 'is-', v: '_' }
 * const stateClasses = state(CONFIG, {
 *   boolean: true,
 *   string: 'value',
 *   disabled: false,
 *   multiple: ['one', 'two'],
 *   object: {
 *     enabled: true,
 *     disabled: false,
 *     true: true,
 *   },
 *   emptyObject: {},
 * })
 *
 * console.log(stateClasses)
 * // > [
 * //   'is-boolean',
 * //   'is-string_value',
 * //   'is-multiple_one',
 * //   'is-multiple_two',
 * //   'is-object_enabled',
 * //   'is-object_true'
 * // ]
 * ```
 * @category General
 * @export
 * @param config - Delimiters configuration.
 * @param modifiers - State modifiers definition. Same as block modifiers.
 * @returns Array of state classes.
 */
export function state(
  config: BemStateConfig,
  modifiers: ModifiersDefinition = {},
): string[] {
  const { sp, v } = config

  return applyModifiers(sp, modifiers, '', v)
}
