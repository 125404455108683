import { r, createSender,createUpdater, createFileSender, createReceiver, baseResource as baseSimpleResource } from '@/plugins/resource/resource'
import { queryPostfix, prefixAPI, urlConstructor, DETAIL_URL, CREATE_URL } from '@/plugins/resource/api'
import { friendlyUrlGenerator } from '@/packages/vue-friendly'
import { baseResource } from '@aspectus/resource'

const MODEL = 'doctor'
const CERTIFICATE_MODEL = MODEL + '/certificate'
const AWARD_MODEL = MODEL + '/award'

const LIST_PATH = '/list/'

const m = postfix => [MODEL, postfix].join('/')

export const DOCTOR_SCIENTIFIC_LIST_URL = prefixAPI(queryPostfix(m('scientific-degree/list/')))
export const DOCTOR_QUALIFICATION_LIST_URL = prefixAPI(queryPostfix(m('qualification/list/')))
export const DOCTOR_RECEPTION_TYPES_URL = prefixAPI(queryPostfix(m('reception-type/list/')))
export const DOCTOR_ATTRIBUTE_CONFIG_FILTERED_URL = prefixAPI('/catalog/attributes-config/filters/', MODEL)

export const DOCTOR_TABS_CLEAR_URL = `/doctors/{label}/ajax/tab/`
export const DOCTOR_TABS_URL = `/doctors/{label}/ajax/tab/{tab}/`
export const DOCTOR_URL = `/doctors/`

export const DOCTOR_FILTERS_SERVICES_URL = urlConstructor(`${MODEL}/{uuid}/service-filters/`, [])

const DOCTOR_DOCUMENTS_CONFIG_URL = 'documents-config/doctors/list/'
const DOCTOR_MODERATION_REQUEST_URL = '/request-moderation/'

export const DOCTOR_LIST_URL: string = urlConstructor(MODEL+LIST_PATH, ['search', 'ids', 'is_favorite'])
export const DOCTOR_CERTIFICATE_CREATE_URL: string = prefixAPI(CERTIFICATE_MODEL + CREATE_URL)
export const DOCTOR_CERTIFICATE_UPDATE_URL: string = CERTIFICATE_MODEL + '/update{/id}/'
export const DOCTOR_CERTIFICATE_LIST_URL: string = urlConstructor(CERTIFICATE_MODEL+'/list/', [])

export const getDoctor = r(DETAIL_URL, MODEL)
export const updateDoctor = r(DETAIL_URL, MODEL, createUpdater)
export const createDoctor = r(CREATE_URL, MODEL, createSender)
export const getDoctorsList = createReceiver(DOCTOR_LIST_URL)
export const createDoctorAwardResource = r(CREATE_URL, AWARD_MODEL, createSender)
export const updateDoctorAwardResource = r('/update{/id}/', AWARD_MODEL, createSender).config('method', 'PATCH')
export const doctorAttributeConfigResource = r('/catalog/attributes-config/', MODEL)

export const doctorServicesResource = friendlyUrlGenerator([
  DOCTOR_URL, 'ajax/services'
], baseResource)
export const doctorReviewsResource = friendlyUrlGenerator([
  DOCTOR_URL, 'ajax/reviews'
], baseResource)

export const doctorAttributeConfigFilteredResource = friendlyUrlGenerator([
  DOCTOR_ATTRIBUTE_CONFIG_FILTERED_URL, ''
], baseSimpleResource)

// CERTIFICATE RESOURCE
// export const updateDoctorCertificate = r(DOCTOR_CERTIFICATE_UPDATE_URL, CERTIFICATE_MODEL, createUpdater)
// export const getDoctorCertificateList = r(DOCTOR_CERTIFICATE_LIST_URL, CERTIFICATE_MODEL)

export const getDoctorDocumentsList = r(DOCTOR_DOCUMENTS_CONFIG_URL, '')
export const doctorRequestResource = r(DOCTOR_MODERATION_REQUEST_URL, MODEL, createSender)

// export const doctorTabsClearResource = createReceiver(DOCTOR_TABS_CLEAR_URL)
// export const doctorTabsResource = createReceiver(DOCTOR_TABS_URL)

export const doctorFiltersServicesResource = createReceiver(DOCTOR_FILTERS_SERVICES_URL)
