export type Key = keyof any

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}
type Composable = any

function find(targets: Composable[], key: Key) {
  for (let i = 0, l = targets.length; i < l; i++) {
    const target = targets[i]

    if (target.hasOwnProperty(key)) {
      return target
    }
  }
}

function createResolver(name: string, unmatchedValue: any) {
  return function (targets: Composable[], key: Key, ...args: any) {
    const target = find(targets, key)

    if (typeof target === 'undefined') {
      return unmatchedValue
    }

    return (<any>Reflect)[name](target, key, ...args.slice(0, -1), target)
  }
}

const proxyHandler = {
  get: createResolver('get', void(0)),
  set: createResolver('set', false),
  has: createResolver('has', false),
}

/**
 * Creates singular proxy object for multiple sources.
 * Resolves parameters like "first in, first out".
 *
 * All the changes made to proxy - will be made to a first found object
 * with same property. Others will not be affected. Getter works the same.
 *
 * **Example:**
 *
 * ```js
 * import multiproxy from '@aspectus/multiproxy'
 *
 * const first = { existing: true, und: void (0) }
 * const second = { existing: true, other: 'other' }
 * const props = multiproxy(first, second)
 *
 * props.existing
 * // > true
 * props.und
 * // > undefined
 * props.other
 * // > 'other'
 *
 * props.und = 'defined'
 *
 * props.und
 * // > 'defined'
 * first.und
 * // > 'defined'
 * second.und
 * // > undefined
 *
 * props.existing = false
 *
 * props.existing
 * // > false
 * first.existing
 * // > false
 * second.existing
 * // > true
 * ```
 *
 * @export
 * @template A - Objects keys.
 * @param args - List of passed arguments. All of them must be an Object-like instances.
 * @returns Proxy object with all properties that his donors has.
 */
export default function multiproxy<A extends keyof any = Key>(
  ...args: PartialRecord<A, any>[]
): typeof args[number] {
  return new Proxy(args, proxyHandler)
}
