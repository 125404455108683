import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, _mergeProps({
    result: $setup.result,
    loadingResult: $setup.loading,
    updateResult: $setup.update,
    changeResult: $setup.change
  }, _ctx.$attrs || {}), null, 16, ["result", "loadingResult"]))
}