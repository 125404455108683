import {
  h,
  SetupContext,
  useSlots,
  VNode,
} from 'vue'
import { i18n } from '@/i18n'
import InputElement from './RootInput.vue'
import { hoc } from './index'
import { CURRENT_LOCALE } from '@/const'

const { d: $d } = i18n.global
const DATE_CONFIG = 'datetime'

function baseFormatFabric(d: any, config: string | unknown = DATE_CONFIG, locale: string) {
  return {
    stringify: (date: Date) => (date ? d(date, config, locale) : ''),
    parse: date => new Date(date),
  }
}

const locale = window.language
export function DatePickerBase(
  props: Record<string, unknown>,
  { emit, slots, attrs }: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
): VNode {
  const {
    formatFabric = baseFormatFabric,
    innerElement = 'date-picker',
    dateFormat = '',
    format = '',
    pickerType = 'date',
  } = props
  return h(
    InputElement,
    {
      ...props,
      // format,
      formatter: baseFormatFabric($d, dateFormat, CURRENT_LOCALE),
      type: pickerType,
      ...attrs,
      innerElement: 'date-picker',
    },
      slots,
  )
}

export const CDatePicker = hoc(DatePickerBase, {
  name: 'CDatePicker',
  blockName: 'control-date-picker',
  innerElement: 'date-picker',
  rangeSeparator: ' - ',
  lang: window.language,
  formatFabric: baseFormatFabric,
  dateFormat: DATE_CONFIG,
})
