<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 8C13.75 4.55 10.95 1.75 7.5 1.75C4.05 1.75 1.25 4.55 1.25 8C1.25 11.45 4.05 14.25 7.5 14.25" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 2.375H5.625C4.40625 6.025 4.40625 9.975 5.625 13.625H5" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.375 2.375C9.98125 4.2 10.2875 6.1 10.2875 8" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.875 10.5V9.875C3.7 10.4813 5.6 10.7875 7.5 10.7875" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.875 6.125C5.525 4.90625 9.475 4.90625 13.125 6.125" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.375 13.875C12.4796 13.875 13.375 12.9796 13.375 11.875C13.375 10.7704 12.4796 9.875 11.375 9.875C10.2704 9.875 9.375 10.7704 9.375 11.875C9.375 12.9796 10.2704 13.875 11.375 13.875Z" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.75 14.25L13.125 13.625" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>