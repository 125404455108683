import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "phone-input-wrapper" }
const _hoisted_2 = { class: "phone-input phone-input--service" }
const _hoisted_3 = { class: "mo-country-option" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "mo-country-option" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "phone-input phone-input--phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_control_multiselect = _resolveComponent("d-control-multiselect")!
  const _component_d_control_simple_input = _resolveComponent("d-control-simple-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_d_control_multiselect, {
        class: "d-control-input d-control-input--appearance_bordered",
        options: $setup.list || [],
        modelValue: $setup.service,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (($setup.service) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => ($setup.save()))
        ],
        name: "service",
        "allow-empty": false,
        disabled: $props.disabled,
        "preselect-first": true,
        label: "title"
      }, {
        option: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "mo-country-option__icon",
              src: option.icon,
              alt: option.iconAlt
            }, null, 8, _hoisted_4)
          ])
        ]),
        singleLabel: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              class: "mo-country-option__icon",
              src: option.icon,
              alt: option.iconAlt
            }, null, 8, _hoisted_6)
          ])
        ]),
        _: 1
      }, 8, ["options", "modelValue", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_d_control_simple_input, _mergeProps({
        class: "d-control-input d-control-input--appearance_bordered",
        rules: "url",
        "onUpdate:modelValue": $setup.save
      }, _ctx.$attrs, {
        modelValue: $setup.link,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.link) = $event)),
        disabled: $props.disabled,
        "input-type": "number",
        "input-label": _ctx.$t('Messengers/Social networks'),
        name: "link"
      }), null, 16, ["modelValue", "disabled", "input-label"]),
      _renderSlot(_ctx.$slots, "label")
    ])
  ]))
}