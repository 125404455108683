import { prefixAPI } from '@/plugins/resource/api'
import { r, createSender } from '@/plugins/resource/resource'

const MODEL = 'mark/favorite'

export const FAVORITE_MEDICAL_URL = prefixAPI('favorite/medical/list/')

export const likesToggleResource = r('/toggle/', MODEL, createSender)
export const likesCountResource = r('/count/', MODEL)
export const likesCountersResource = r('/counters/', MODEL)
