<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5834 1.77578C12.7663 1.45121 13.2337 1.45121 13.4166 1.77578L16.5577 7.34859C16.9114 7.97602 17.5205 8.41857 18.2265 8.56102L24.4972 9.82626C24.8624 9.89995 25.0068 10.3445 24.7547 10.6188L20.4253 15.3282C19.9378 15.8584 19.7052 16.5745 19.7878 17.2899L20.5223 23.6447C20.5651 24.0148 20.1869 24.2895 19.8482 24.1345L14.0314 21.4723C13.3765 21.1725 12.6235 21.1725 11.9686 21.4723L6.15185 24.1345C5.81307 24.2895 5.43494 24.0148 5.47772 23.6447L6.21215 17.2899C6.29484 16.5745 6.06218 15.8584 5.57474 15.3282L1.24533 10.6188C0.993175 10.3445 1.13761 9.89995 1.50282 9.82626L7.77352 8.56102C8.47953 8.41857 9.08865 7.97602 9.4423 7.34859L12.5834 1.77578Z" stroke="url(#paint0_linear_1034_16881)" stroke-width="2"/>
    <defs>
      <linearGradient id="paint0_linear_1034_16881" x1="34.2716" y1="-36.6368" x2="58.3408" y2="19.6761" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>