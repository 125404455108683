import type { App } from 'vue'
import { createVfm, ModalsContainer, VueFinalModal } from 'vue-final-modal'

export const vfm = createVfm() as any

export default {
  install(app: App<Element>): void {
    app
      .use(vfm)
      .component('ModalsContainer', ModalsContainer)
      .component('VueFinalModal', VueFinalModal)
  },
}
