<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M20 13.0004C20 18.0004 16.5 20.5005 12.34 21.9505C12.1222 22.0243 11.8855 22.0207 11.67 21.9405C7.5 20.5005 4 18.0004 4 13.0004V6.00045C4 5.73523 4.10536 5.48088 4.29289 5.29334C4.48043 5.10581 4.73478 5.00045 5 5.00045C7 5.00045 9.5 3.80045 11.24 2.28045C11.4519 2.09945 11.7214 2 12 2C12.2786 2 12.5481 2.09945 12.76 2.28045C14.51 3.81045 17 5.00045 19 5.00045C19.2652 5.00045 19.5196 5.10581 19.7071 5.29334C19.8946 5.48088 20 5.73523 20 6.00045V13.0004Z" stroke="url(#paint0_linear_2498_20186)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9 12H15" stroke="url(#paint1_linear_2498_20186)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 9V15" stroke="url(#paint2_linear_2498_20186)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_2498_20186" x1="23.3448" y1="-21.7618" x2="41.8039" y2="12.7821" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2498_20186" x1="16.2543" y1="10.8121" x2="16.4117" y2="13.0209" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2498_20186" x1="13.2091" y1="1.87265" x2="17.7175" y2="3.63066" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
  </defs>
</svg>
</template>