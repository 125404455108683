import { App, defineAsyncComponent } from 'vue';

import InfiniteTemplateResultsCollector from './InfiniteTemplateResultsCollector'
import UrlParametersController from './UrlParametersController'
import AppRenderer from './AppRenderer.vue'

// const PaginatedResourceController = defineAsyncComponent(() => import('./PaginatedResourceController.vue'));
const InfiniteResultsCollector = defineAsyncComponent(() => import('./InfiniteResultsCollector'));
const ResourceLoaderController = defineAsyncComponent(() => import('./ResourceLoaderController'));
// const PaginationController = defineAsyncComponent(() => import('./PaginationController'));
// const DelayedExecutorController = defineAsyncComponent(() => import('./DelayedExecutorController'));


export default {
  install(app: App<Element>): void {
    app
      // .component('PaginatedResourceController', PaginatedResourceController)
      .component('InfiniteResultsCollector', InfiniteResultsCollector)
      .component('InfiniteTemplateResultsCollector', InfiniteTemplateResultsCollector)
      .component('ResourceLoaderController', ResourceLoaderController)
      // .component('PaginationController', PaginationController)
      // .component('DelayedExecutorController', DelayedExecutorController)
      .component('UrlParametersController', UrlParametersController)
      .component('AppRenderer', AppRenderer)
  }
};
