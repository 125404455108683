import { mergeProps as _mergeProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_up_progress = _resolveComponent("up-progress")!
  const _component_i_cancel = _resolveComponent("i-cancel")!
  const _component_ControlButton = _resolveComponent("ControlButton")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), {
    class: _normalizeClass(_ctx.classes.root.value)
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.gui), _mergeProps({
        class: _ctx.classes.gui.value,
        onSave: _ctx.onSave
      }, _ctx.guiAttrs), _createSlots({ _: 2 }, [
        _renderList(_ctx.slots, (_, sn) => {
          return {
            name: sn,
            fn: _withCtx((sd) => [
              _renderSlot(_ctx.$slots, sn, _mergeProps({ sn: sn }, sd))
            ])
          }
        })
      ]), 1040, ["class", "onSave"])),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.classes.overlay.value)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.classes.controls.value)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.classes.control.m({ 'contains': 'progress' }).value)
              }, [
                _createVNode(_component_up_progress, {
                  value: _ctx.progress + _ctx.progressShift,
                  class: _normalizeClass(_ctx.classes.progress.value)
                }, null, 8, ["value", "class"])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.classes.control.value)
              }, [
                _createVNode(_component_ControlButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel())),
                  appearance: "square",
                  type: "button",
                  class: _normalizeClass(_ctx.classes.cancel.value)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_i_cancel)
                  ]),
                  _: 1
                }, 8, ["class"])
              ], 2)
            ], 2)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class"]))
}