import {
  getAuth,
} from 'firebase/auth';

import {
  initializeApp,
  FirebaseApp
} from 'firebase/app';

import {
  getConfig
} from '@/modules/firebase/config';

import { toggleGoogleSignIn } from './providers/google';
import { toggleFacebookSignIn } from './providers/facebook';
import { handleRedirectResult } from './handler';
import { getOs, APPLE_AVAILABLE_OS_LIST, isIosSafari } from './helpers';

// Initialize Firebase
initializeApp(getConfig());

// Get authentication instance
export const auth = getAuth();

export function initFirebase(): void {
  const googleBtn = document.getElementById('js-google-sign-in');
  const facebookBtn = document.getElementById('js-facebook-sign-in');
  console.log(googleBtn);
  if (googleBtn) {
    toggleGoogleSignIn(googleBtn, auth);
  }
  
  if (facebookBtn) {
    toggleFacebookSignIn(facebookBtn, auth);
    
    // if (isIosSafari()) {
    //   if (facebookBtn.parentElement) {
    //     facebookBtn.parentElement.style.display = 'block';
    //   }
    //   return;
    // }
    
    // if (googleBtn && googleBtn.parentElement) {
    //   googleBtn.parentElement.classList.remove('g-cols--6');
    //   googleBtn.parentElement.classList.add('g-cols--12');
    // }
  }
}
// Initialize Firebase when the document is ready
document.addEventListener('DOMContentLoaded', initFirebase);

handleRedirectResult(auth);
