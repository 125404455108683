<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.25 18.75C26.25 19.413 25.9866 20.0489 25.5178 20.5178C25.0489 20.9866 24.413 21.25 23.75 21.25H8.75L3.75 26.25V6.25C3.75 5.58696 4.01339 4.95107 4.48223 4.48223C4.95107 4.01339 5.58696 3.75 6.25 3.75H23.75C24.413 3.75 25.0489 4.01339 25.5178 4.48223C25.9866 4.95107 26.25 5.58696 26.25 6.25V18.75Z" stroke="url(#paint0_linear_1034_16884)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_1034_16884" x1="30.9537" y1="-22.9776" x2="49.0056" y2="19.257" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>