import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ds-panel" }
const _hoisted_2 = { class: "ds-panel__element" }
const _hoisted_3 = { class: "main-title main-title--variant_modal ds-caption ds-caption--appearance_center" }
const _hoisted_4 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString($props.message.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($props.message.text)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "ds-caption ds-caption--size_md ds-caption--appearance_wrapped ds-caption--appearance_center",
            innerHTML: $props.message.text
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ])
  ]))
}