import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const APPOINTMENT_PREFIX = 'cabinet:appointment'
const p = (name: string) => APPOINTMENT_PREFIX + ':' + name

export const APPOINTMENT_LIST_VIEW_NAME = p('list')

const View = () => import('./views/View.vue')

const BASE_MODEL_NAME = 'appointment/'


export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global

  return [
    route('list/', View, APPOINTMENT_LIST_VIEW_NAME, {
      meta: {
        title: () => $t('Appoointments'),
      }
    }),
    
  ]
}

export { BASE_MODEL_NAME }