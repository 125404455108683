<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_1520_15907)">
    <path d="M11.25 3.75029L14.2499 6.75029M15.8805 5.10929C16.277 4.71285 16.4998 4.17514 16.4999 3.61443C16.4999 3.05372 16.2773 2.51594 15.8808 2.11941C15.4844 1.72288 14.9467 1.50007 14.386 1.5C13.8253 1.49993 13.2875 1.7226 12.891 2.11904L2.88145 12.1308C2.70732 12.3044 2.57854 12.5182 2.50645 12.7533L1.5157 16.0173C1.49632 16.0821 1.49485 16.151 1.51146 16.2167C1.52807 16.2823 1.56214 16.3422 1.61005 16.39C1.65796 16.4379 1.71792 16.4718 1.78357 16.4883C1.84922 16.5048 1.91812 16.5033 1.98295 16.4838L5.2477 15.4938C5.48258 15.4224 5.69633 15.2944 5.8702 15.121L15.8805 5.10929Z" stroke="#008CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_1520_15907">
      <rect width="18" height="18" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>