import clsx, { ClassValue } from 'clsx'
import { applyModifiers, state, ModifiersDefinition } from '@aspectus/bem'

export type BemClassFactoryConfig = {
  /** Modifier delimiter. */
  m?: string
  /** Modifier value delimiter. */
  v?: string
  /** State prefix delimiter. */
  sp?: string
  /** State value delimiter. */
  sv?: string
}
export type BemContext = {
  /** Modifiers definition object. */
  m: ModifiersDefinition
  /** Compiled modifiers. */
  mc: string
  /** States definition object. */
  s: ModifiersDefinition
  /** Compiled state. */
  sc: string
  /** Additional classes. */
  cls: string
}
export type BemElementDefinition = {
  /** Internal bem data. */
  _bem: {
    bases: string
    config: BemClassFactoryConfig
    context: BemContext
  }
  value: string
  mix: typeof mix
  m: typeof addModifiers
  s: typeof addState
}
export type BemElement = (() => string) & BemElementDefinition

/**
 * Mixes class name to an element definition.
 *
 * @param classes - List of class values that `clsx` supports.
 * @returns New element definition.
 */
function mix(this: BemElement, ...classes: ClassValue[]): BemElement {
  const { _bem } = this
  const { context } = _bem

  return makeClassName(_bem.config, _bem.bases, {
    ...context,
    cls: clsx(context.cls, ...classes)
  })
}

function addModifiers(this: BemElement, modifiers: ModifiersDefinition): BemElement {
  const { _bem } = this
  const { context, config } = _bem
  const m = { ...context.m, ...modifiers }
  const mc = applyModifiers(_bem.bases, m, config.m || '', config.v || '').join(' ')

  return makeClassName(config, _bem.bases, { ...context, m, mc })
}

function addState(this: BemElement, stateProps: ModifiersDefinition): BemElement {
  const { _bem } = this
  const { context, config } = _bem
  const s = { ...context.s, ...stateProps }
  const sc = state({ sp: config.sp || '', v: config.sv || '' }, s).join(' ')

  return makeClassName(config, _bem.bases, { ...context, s, sc })
}

const DEFAULT_CONTEXT: BemContext = {
  m: {},
  mc: '',
  s: {},
  sc: '',
  cls: '',
}

/**
 * Checks if provided value is a class name object from `makeClassName` factory.
 *
 * @category Functions
 * @export
 * @param value - Some value.
 * @returns Boolean - whether value is BemElement.
 */
export function isClassName(value: any): value is BemElement {
  return typeof value === 'function' && value._bem && value.mix === mix
}

/**
 * Makes extendable class name object.
 *
 * **Example:**
 * Factory method creates a class name object that might be extended "in bem way":
 *
 * ```js
 * const block = makeClassName(CONFIG, 'block')
 * const modified = block
 *   // By mixing other classes:
 *   .mix('one', { different: true })
 *   // By describing additional modifiers:
 *   .m({
 *      different: false, other: true, value: '4',
 *      multivalue: ['one', 'two']
 *    })
 *   // By defining states:
 *   .s({
 *      different: false, other: true, value: '4',
 *      multivalue: ['one', 'two']
 *    })
 * ```
 *
 * Result can be received by either calling it a function or accessing
 * attribute `.value`:
 *
 * ```js
 * console.log(block.value)
 * // > 'block'
 * console.log(modified())
 * // Multilined for readability:
 * // > (
 * //   'block ' +
 * //   'one different ' +
 * //   'block--other block--value_4 ' +
 * //   'block--multivalue_one block--multivalue_two ' +
 * //   'is-other is-value_4 is-multivalue_one is-multivalue_two'
 * // )
 * ```
 *
 * @category General
 * @export
 * @param config - Delimiters config.
 * @param bases - String that defines base block/element class name.
 * @param context - It's optional initial context for block name generation
 *    By default it looks like this: <br />
 *
 *    `{ m: {}, mc: '', s: {}, sc: '', cls: '' }`<br />
 *
 *    Context should be used if your class name should have some modifiers
 *    from the beginning. In most cases - just omit this parameter.
 * @returns Extendable class name object.
 */
export function makeClassName(
  config: BemClassFactoryConfig,
  bases: string,
  context: BemContext = DEFAULT_CONTEXT
): BemElement {
  const result = () => result.value
  result.value = clsx(bases, context.cls, context.mc, context.sc)
  result.mix = mix
  result.m = addModifiers
  result.s = addState
  result._bem = { bases, context, config }

  return result
}
