import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: $setup.blockClasses }, { 'aria-disabled': $props.disabled }), [
    _createElementVNode("span", {
      class: _normalizeClass([`${$props.blockName}__body`])
    }, [
      _renderSlot(_ctx.$slots, "prepend", {
        valid: !$props.invalid
      }),
      ($props.startIcon || _ctx.$slots.startIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_prepend`])
          }, [
            _renderSlot(_ctx.$slots, "startIcon", {
              valid: !$props.invalid
            }, () => [
              _createVNode(_component_AppIcon, {
                name: $props.startIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent($props.innerElement), _mergeProps({
        class: [`${$props.blockName}__element`, `${$props.blockName}__element--primary`]
      }, 'phone-input' === $props.innerElement ? { ..._ctx.$attrs } : { value: $props.value, ..._ctx.$attrs }, {
        name: $props.name,
        type: $setup.inputType,
        readonly: $props.readonly,
        disabled: $props.disabled,
        id: $props.id
      }), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, name) => {
          return {
            name: name,
            fn: _withCtx((bound) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(bound || {})))
            ])
          }
        })
      ]), 1040, ["class", "name", "type", "readonly", "disabled", "id"])),
      ($props.endIcon || _ctx.$slots.endIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_append`])
          }, [
            _renderSlot(_ctx.$slots, "endIcon", {
              valid: !$props.invalid
            }, () => [
              _createVNode(_component_AppIcon, {
                name: $props.endIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append", {
        valid: !$props.invalid
      })
    ], 2),
    _renderSlot(_ctx.$slots, "slider"),
    ($setup.isPasswordField)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "change-type",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleType()))
        }, _toDisplayString($setup.inputType === $setup.p ? _ctx.$t('Show') : _ctx.$t('Hide')), 1))
      : _createCommentVNode("", true)
  ], 16))
}