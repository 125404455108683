import { useToast } from 'vue-toast-notification'

const toast = useToast()

const SUCCESS_TYPE = 'success'
const ERROR_TYPE = 'error'
const INFO_TYPE = 'info'

enum ToastPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
  TOP_LEFT = 'top-left',
  BOTTOM_LEFT = 'bottom-left'
}

enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

interface ToastOptions {
  position: ToastPosition;
}

const showToast = (
  type: ToastType,
  message: string,
  options: ToastOptions = { position: ToastPosition.BOTTOM_LEFT }
): void => {
  toast[type](message, options)
}

export const useSuccessToast = (message: string, options: ToastOptions = { position: ToastPosition.BOTTOM_LEFT } ): void => {
  showToast(ToastType.SUCCESS, message, options)
}

export const useErrorToast = (message: string, options: ToastOptions = { position: ToastPosition.BOTTOM_LEFT } ): void => {
  showToast(ToastType.ERROR, message, options)
}

export const useInfoToast = (message: string, options: ToastOptions = { position: ToastPosition.BOTTOM_LEFT } ): void => {
  showToast(ToastType.INFO, message, options)
}