import { App } from 'vue'

import UpCropper from './components/Cropper.vue'
import UpCropperModal from './components/CropperModal.vue'
import UpImage from './components/Image.vue'
import UpFile from './components/File.vue'
import UpProgress from './components/Progress.vue'
import UpUploader from './components/Uploader.vue'
import UpImageLabel from './components/slots/ImageLabel.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  install(app: App<Element>) {
    app.component('vue-cropper', Cropper)
    app.component('up-image', UpImage)
    app.component('up-file', UpFile)
    app.component('up-progress', UpProgress)
    app.component('up-uploader', UpUploader)
    app.component('up-crop', UpCropper)
    app.component('up-crop-modal', UpCropperModal)
    app.component('up-image-label', UpImageLabel)
  }
}
