import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'

// import { getCookie } from '@utils/cookies'

export const HEADERS_MIDDLEWARE = headersMiddleware(overrideMiddleware({
  'Accept-Language': window.language,
  'x-language': window.language,
}))
