import { App } from 'vue'
import { tableWrapper } from './table'
import { navigationScroll } from './navigationScroll'

const register = (app: App<Element>): void => {
  app.directive('table-wrapper', tableWrapper)
  app.directive('navigation-scroll', navigationScroll)
}

export default {
  register,
}
