const ARIA_SELECTED_ATTR = 'aria-selected'
const OPEN_ATTR = 'open'
const BREAKPOINT = 1200

const xsTabs = () => {
  const tabs: Element[] = [...document.getElementsByClassName('js-tab-xs')]
  tabs.forEach((tab: Element) => {
    const tabButtons: Element[] = [...tab.getElementsByClassName('rs-accordion')]
    tabButtons.forEach((button) => {
      button.addEventListener('click', () => {
        tabs.forEach((btn) => {
          btn.removeAttribute(ARIA_SELECTED_ATTR)
          btn.removeAttribute(OPEN_ATTR)
        })
        tab.setAttribute(ARIA_SELECTED_ATTR, 'true')
      })
    })
  })
  if (tabs.length > 0) {
    const [t] = tabs
    const tabButtons: Element[] = [...t.getElementsByClassName('rs-accordion')]
    tabButtons[0].dispatchEvent(new Event('click'))
    t.setAttribute(OPEN_ATTR, 'true')
  }
}

const xlTabs = () => {
  const tabsXl = [...document.getElementsByClassName('js-tab-xl')];
  const tabContentParent = document.getElementById('js-rs-tabpanel');
  if (tabContentParent) {
    const tabContent = [...tabContentParent.children];
    tabsXl.forEach(tab => {
      tab.addEventListener('click', () => {
        tabsXl.forEach(btn => btn.setAttribute('aria-selected', 'false'));
        tab.setAttribute('aria-selected', 'true');
        tabContent.forEach(content => (content as HTMLElement).style.display = 'none');
        const current = tabContent.find(content => (content as HTMLElement).dataset.tab === (tab as HTMLElement).dataset.tab)
        if (current) {
          (current as HTMLElement).style.display = 'block';
        }
      });
    });
    if (tabsXl.length > 0) {
      tabsXl[0].dispatchEvent(new Event('click'))
    }
  }
}

const pureTabs = () => {
  const ww = window.innerWidth
  const action = ww < BREAKPOINT ? xsTabs : xlTabs
  action()
}

export const dropDownMenu = () => {
  const triggers: Element[] = [...document.getElementsByClassName('js-dropdown-trigger')]
  const menus = triggers.map(trigger => document.getElementById((trigger as HTMLElement).dataset.trigger));
  const closeAllMenus = () => {
    menus.forEach(menu => {
      if (menu) {
        menu.classList.remove('is-active');
      }
    });
    triggers.forEach(trigger => {
      trigger.classList.remove('is-active')
    })
  };
  triggers.forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
      event.stopPropagation()
      const context = document.getElementById((trigger as HTMLElement).dataset.trigger)
      if (context) {
        trigger.classList.toggle('is-active')
        context.classList.toggle('is-active')
      }
    })
  })
  document.addEventListener('click', (event) => {
    const target = event.target as Node
    const isClickInsideMenu = menus.some(menu => menu && menu.contains(target))
    const isClickInsideTrigger = triggers.some(trigger => trigger.contains(target))

    if (!isClickInsideMenu && !isClickInsideTrigger) {
      closeAllMenus();
    }
  });
}

function scrollFunction() {
  const btnScroll = document.getElementById('scroll-top')
  const offset = 60
  if (!btnScroll) return 
  if (document.body.scrollTop > offset ||
    document.documentElement.scrollTop > offset) {
    btnScroll.classList.add('is-active')
    return
  } 
  btnScroll.classList.remove('is-active')
}

export const scrolly = () => {
  // Scroll to Up
  scrollFunction()
  window.addEventListener('scroll', scrollFunction, { passive: true })
}


const fns = [dropDownMenu, scrolly]

const fnsLoader = () => {
  for (const fn of fns) {
    fn()
  }
}

if ('development' === process.env.NODE_ENV) {
  window.addEventListener('load', () => {
    fnsLoader()
  })
} else {
  document.addEventListener('DOMContentLoaded', () => {
    fnsLoader()
  })
}