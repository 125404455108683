import { App, defineAsyncComponent } from 'vue'

const LoginForm = defineAsyncComponent(() => import('@/modules/auth/components/LoginForm.vue'))
const SignUp = defineAsyncComponent(() => import('@m/auth/components/SignUp.vue'))
const SetNewPassword = defineAsyncComponent(() => import('@m/auth/components/SetNewPassword.vue'))
const PasswordReset = defineAsyncComponent(() => import('@m/auth/components/PasswordReset.vue'))
const AuthProvider = defineAsyncComponent(() => import('@m/auth/components/AuthProvider.vue'))
const UserAccessState = defineAsyncComponent(() => import('@m/auth/components/UserAccessState.vue'))
const SocialDataComplete = defineAsyncComponent(() => import('@m/auth/components/SocialDataComplete.vue'))
const AgreementCheckbox = defineAsyncComponent(() => import('@m/auth/components/AgreementCheckbox.vue'))
const AuthForbidden = defineAsyncComponent(() => import('@m/auth/components/Forbidden.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('LoginForm', LoginForm)
      .component('SignUp', SignUp)
      .component('SetNewPassword', SetNewPassword)
      .component('PasswordReset', PasswordReset)
      .component('AuthProvider', AuthProvider)
      .component('UserAccessState', UserAccessState)
      .component('SocialDataComplete', SocialDataComplete)
      .component('AgreementCheckbox', AgreementCheckbox)
      .component('AuthForbidden', AuthForbidden)
  }
}
