<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 1.5V3.75" stroke="url(#paint0_linear_1765_12943)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 1.5V3.75" stroke="url(#paint1_linear_1765_12943)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.625 6.81738H15.375" stroke="url(#paint2_linear_1765_12943)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z" stroke="url(#paint3_linear_1765_12943)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.99588 10.2749H9.00262" stroke="url(#paint4_linear_1765_12943)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.22244 10.2749H6.22918" stroke="url(#paint5_linear_1765_12943)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.22244 12.5249H6.22918" stroke="url(#paint6_linear_1765_12943)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_1765_12943" x1="7.20905" y1="-1.17276" x2="9.70467" y2="1.42226" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1765_12943" x1="13.2091" y1="-1.17276" x2="15.7047" y2="1.42226" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1765_12943" x1="18.0404" y1="5.62949" x2="18.1147" y2="7.84702" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1765_12943" x1="18.5722" y1="-13.857" x2="29.9148" y2="11.963" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint4_linear_1765_12943" x1="9.52997" y1="9.00898" x2="10.1325" y2="10.4159" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint5_linear_1765_12943" x1="6.75653" y1="9.00898" x2="7.3591" y2="10.4159" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint6_linear_1765_12943" x1="6.75653" y1="11.259" x2="7.3591" y2="12.6659" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>