<template>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<g id="vuesax/linear/global">
<g id="global">
<path id="Vector" d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M6.0002 2.25H6.7502C5.2877 6.63 5.2877 11.37 6.7502 15.75H6.0002" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_3" d="M11.25 2.25C12.7125 6.63 12.7125 11.37 11.25 15.75" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_4" d="M2.25 12V11.25C6.63 12.7125 11.37 12.7125 15.75 11.25V12" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_5" d="M2.25 6.7502C6.63 5.2877 11.37 5.2877 15.75 6.7502" stroke="#008CFF" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
</svg>
</template>