<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<g clip-path="url(#clip0_2730_22183)">
<path d="M8.0013 5.83428V8.50095M8.0013 11.1676H8.00797M13.3346 9.16762C13.3346 12.501 11.0013 14.1676 8.22797 15.1343C8.08274 15.1835 7.92499 15.1811 7.7813 15.1276C5.0013 14.1676 2.66797 12.501 2.66797 9.16762V4.50095C2.66797 4.32414 2.73821 4.15457 2.86323 4.02955C2.98826 3.90452 3.15782 3.83428 3.33464 3.83428C4.66797 3.83428 6.33464 3.03428 7.49464 2.02095C7.63587 1.90028 7.81554 1.83398 8.0013 1.83398C8.18707 1.83398 8.36673 1.90028 8.50797 2.02095C9.67464 3.04095 11.3346 3.83428 12.668 3.83428C12.8448 3.83428 13.0143 3.90452 13.1394 4.02955C13.2644 4.15457 13.3346 4.32414 13.3346 4.50095V9.16762Z" stroke="#E74C3C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2730_22183">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
</template>