
import { App, defineAsyncComponent } from 'vue'

const View = defineAsyncComponent(() => import('./View.vue'))
const Modal = defineAsyncComponent(() => import('./Modal.vue'))

const install = (app: App<Element>, prefix = 'Video'): void => {
  app
    .component(`${prefix}View`, View)
    .component(`${prefix}Modal`, Modal)
}

export default {
  install,
}
