
import { App, defineAsyncComponent } from 'vue'

const UiTrigger = defineAsyncComponent(() => import('./Trigger.vue'))

const install = (app: App<Element>, prefix = 'Search'): void => {
  app
    .component(`${prefix}Trigger`, UiTrigger)
}

export default {
  install,
}
