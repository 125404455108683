<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6864 8.99994C11.6864 10.4849 10.4864 11.6849 9.00141 11.6849C7.51641 11.6849 6.31641 10.4849 6.31641 8.99994C6.31641 7.51494 7.51641 6.31494 9.00141 6.31494C10.4864 6.31494 11.6864 7.51494 11.6864 8.99994Z" stroke="url(#paint0_linear_1765_6586)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.99891 15.2025C11.6464 15.2025 14.1139 13.6425 15.8314 10.9425C16.5064 9.88504 16.5064 8.10754 15.8314 7.05004C14.1139 4.35004 11.6464 2.79004 8.99891 2.79004C6.35141 2.79004 3.88391 4.35004 2.16641 7.05004C1.49141 8.10754 1.49141 9.88504 2.16641 10.9425C3.88391 13.6425 6.35141 15.2025 8.99891 15.2025Z" stroke="url(#paint1_linear_1765_6586)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_1765_6586" x1="12.809" y1="-0.0640374" x2="17.1174" y2="10.016" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1765_6586" x1="19.406" y1="-11.9547" x2="28.2155" y2="12.417" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>