<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0028 13.4299C13.7259 13.4299 15.1228 12.0331 15.1228 10.3099C15.1228 8.58681 13.7259 7.18994 12.0028 7.18994C10.2797 7.18994 8.88281 8.58681 8.88281 10.3099C8.88281 12.0331 10.2797 13.4299 12.0028 13.4299Z" stroke="url(#paint0_linear_3924_15920)" stroke-width="1.5"/>
    <path d="M3.61776 8.49C5.58776 -0.169998 18.4178 -0.159997 20.3778 8.5C21.5278 13.58 18.3678 17.88 15.5978 20.54C13.5878 22.48 10.4078 22.48 8.38776 20.54C5.62776 17.88 2.46776 13.57 3.61776 8.49Z" stroke="url(#paint1_linear_3924_15920)" stroke-width="1.5"/>
    <defs>
      <linearGradient id="paint0_linear_3924_15920" x1="16.4273" y1="-0.222504" x2="21.4337" y2="11.4906" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3924_15920" x1="24.2257" y1="-21.7519" x2="41.8863" y2="13.8853" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008CFF"/>
        <stop offset="1" stop-color="#41DEBD"/>
      </linearGradient>
    </defs>
  </svg>
</template>