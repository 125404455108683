
import { App, defineAsyncComponent } from 'vue'

const Cancel = defineAsyncComponent(() => import('./Cancel.vue'))
const CancelSimple = defineAsyncComponent(() => import('./CancelSimple.vue'))
const FormFields = defineAsyncComponent(() => import('./FormFields.vue'))
const FormBase = defineAsyncComponent(() => import('./FormBase.vue'))
const FormExtended = defineAsyncComponent(() => import('./FormExtended.vue'))
const Button = defineAsyncComponent(() => import('./Button.vue'))
const Modal = defineAsyncComponent(() => import('./Modal.vue'))
const ContinueModal = defineAsyncComponent(() => import('./continue/Modal.vue'))
const ContinueDoctorInfo = defineAsyncComponent(() => import('./continue/DoctorInfo.vue'))
const ContinueMedicalInfo = defineAsyncComponent(() => import('./continue/MedicalInfo.vue'))
const CartButton = defineAsyncComponent(() => import('./cart/Button.vue'))
// const ContinueButton = defineAsyncComponent(() => import('./continue/Button.vue'))

const install = (app: App<Element>, prefix = 'Appointment'): void => {
  app
    .component(`${prefix}Cancel`, Cancel)
    .component(`${prefix}CancelSimple`, CancelSimple)
    .component(`${prefix}FormFields`, FormFields)
    .component(`${prefix}FormBase`, FormBase)
    .component(`${prefix}FormExtended`, FormExtended)
    .component(`${prefix}Button`, Button)
    .component(`${prefix}Modal`, Modal)
    .component(`${prefix}ContinueModal`, ContinueModal)
    .component(`${prefix}ContinueDoctorInfo`, ContinueDoctorInfo)
    .component(`${prefix}ContinueMedicalInfo`, ContinueMedicalInfo)
    .component(`${prefix}CartButton`, CartButton)
    // .component(`${prefix}ContinueButton`, ContinueButton)
}

export default {
  install,
}
