import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDirective as _resolveDirective, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "multiselect__value" }
const _hoisted_3 = { class: "i-count i-count--variant_fixed" }
const _hoisted_4 = { class: "i-count__text" }
const _hoisted_5 = { class: "multiselect-close" }
const _hoisted_6 = { class: "g-row g-row--align_center g-row--space_md" }
const _hoisted_7 = { class: "g-cell g-cols" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "mo-button__text" }
const _hoisted_10 = { class: "g-cell g-cols" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "mo-button__text" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onUpdate:modelValue"]
const _hoisted_15 = { class: "multiselect-checkbox__label" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "observer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_intersection_observer = _resolveDirective("intersection-observer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-input", { error: $props.errors[$props.name] || !$setup.isValid, success: $props.isSuccess }])
  }, [
    _createVNode($setup["VueMultiselect"], _mergeProps({
      class: ["d-control-input__element", { "is-multiple": $props.multiple, "is-searchable": $props.searchable }]
    }, _ctx.$attrs, {
      options: $setup.localOptions,
      label: $props.labelBy,
      "track-by": $props.trackBy,
      placeholder: $props.placeholder,
      disabled: $props.disabled,
      "allow-empty": $props.allowEmpty,
      modelValue: $setup.model,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
        $setup.check
      ],
      required: $props.rules,
      value: $setup.model,
      multiple: $props.multiple,
      "show-labels": false,
      "custom-label": $props.customLabel
    }), _createSlots({
      selection: _withCtx(({values}) => [
        (Array.isArray(values) && values.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(values.map(el => $setup.getOptionLabel(el)).join(', ')), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(values.length), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(({ deactivate, clear }) => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("a", {
                class: "mo-button mo-button--mini",
                onClick: deactivate
              }, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('Apply')), 1)
              ], 8, _hoisted_8)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("a", {
                class: "mo-button mo-button--mini mo-button--variant_bordered-primary",
                onClick: ($event: any) => {clear(); deactivate()}
              }, [
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('Clear')), 1)
              ], 8, _hoisted_11)
            ])
          ])
        ])
      ]),
      option: _withCtx(({ option }) => [
        ($props.multiple)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "multiselect-checkbox",
              onClick: _withModifiers(($event: any) => ($setup.select(option)), ["self"])
            }, [
              _withDirectives(_createElementVNode("input", {
                class: "multiselect-checkbox__input",
                style: {"pointer-events":"none"},
                type: "checkbox",
                "onUpdate:modelValue": ($event: any) => ((option.selected) = $event)
              }, null, 8, _hoisted_14), [
                [_vModelCheckbox, option.selected]
              ]),
              _createElementVNode("p", _hoisted_15, _toDisplayString($setup.getOptionLabel(option)), 1)
            ], 8, _hoisted_13))
          : (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString($setup.getOptionLabel(option)), 1))
      ]),
      noResult: _withCtx(({ search }) => [
        _createTextVNode(_toDisplayString(_ctx.$t("No elements found. Consider changing the search query.")), 1)
      ]),
      _: 2
    }, [
      _renderList($setup.eSlots, (name, key) => {
        return {
          name: name,
          fn: _withCtx((binded) => [
            _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
          ])
        }
      }),
      ($props.pagination && $props.pagination.total > $props.pagination.offset + $props.pagination.limit)
        ? {
            name: "afterList",
            fn: _withCtx(() => [
              _createElementVNode("li", null, [
                _withDirectives(_createElementVNode("div", _hoisted_17, null, 512), [
                  [_directive_intersection_observer, $setup.visibilityChanged]
                ])
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["class", "options", "label", "track-by", "placeholder", "disabled", "allow-empty", "modelValue", "required", "value", "multiple", "custom-label"]),
    _renderSlot(_ctx.$slots, "label")
  ], 2))
}