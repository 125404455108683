import { friendlyUrlGenerator } from '@/packages/vue-friendly'
import { baseResource } from '@aspectus/resource'
import { urlConstructor } from '@/plugins/resource/api'
import { r, createSender } from '@/plugins/resource/resource'

// Models
const MODEL = 'news'

export const NEWS_CATALOG_URL = `/${MODEL}/`
export const NEWS_RATING_SET_URL = `/posts/rate/{id}/`

// filter resources
export const newsCatalogResource = friendlyUrlGenerator([
  NEWS_CATALOG_URL, 'ajax'
], baseResource)
export const newsRatingSetResource = r(NEWS_RATING_SET_URL, MODEL, createSender)

export const NEWS_LIST_URL: string = urlConstructor(MODEL+'/list/', ['search', 'ids', 'is_favorite'])