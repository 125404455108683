import { App, defineAsyncComponent } from 'vue'

const Modal = defineAsyncComponent(() => import('./Modal.vue'))
const Trigger = defineAsyncComponent(() => import('./Trigger.vue'))

const install = (app: App<Element>, prefix = 'CookiesAgreement'): void => {
  app
    .component(`${prefix}Modal`, Modal)
    .component(`${prefix}Trigger`, Trigger)
}

export default {
  install,
}
