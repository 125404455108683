<svg width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/mobile">
<g id="mobile">
<path id="Vector" d="M34.6666 11.4479V32.2812C34.6666 40.6146 32.5833 42.6979 24.2499 42.6979H11.7499C3.41659 42.6979 1.33325 40.6146 1.33325 32.2812V11.4479C1.33325 3.11458 3.41659 1.03125 11.7499 1.03125H24.2499C32.5833 1.03125 34.6666 3.11458 34.6666 11.4479Z" stroke="url(#paint0_linear_1766_11147)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M22.1666 8.32422H13.8333" stroke="url(#paint1_linear_1766_11147)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_3" d="M17.9998 36.6576C19.7832 36.6576 21.229 35.2118 21.229 33.4284C21.229 31.645 19.7832 30.1992 17.9998 30.1992C16.2164 30.1992 14.7706 31.645 14.7706 33.4284C14.7706 35.2118 16.2164 36.6576 17.9998 36.6576Z" stroke="url(#paint2_linear_1766_11147)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_1766_11147" x1="41.635" y1="-48.4642" x2="80.0813" y2="23.4955" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
<linearGradient id="paint1_linear_1766_11147" x1="23.9087" y1="7.13633" x2="24.0222" y2="9.35052" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
<linearGradient id="paint2_linear_1766_11147" x1="22.5791" y1="22.5274" x2="27.7607" y2="34.6503" gradientUnits="userSpaceOnUse">
<stop stop-color="#008CFF"/>
<stop offset="1" stop-color="#41DEBD"/>
</linearGradient>
</defs>
</svg>