<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<g clip-path="url(#clip0_2730_22111)">
<path d="M7.9987 4.666V8.665L5.33203 9.998M14.6654 8.665C14.6654 12.346 11.6806 15.33 7.9987 15.33C4.3168 15.33 1.33203 12.346 1.33203 8.665C1.33203 4.98402 4.3168 2 7.9987 2C11.6806 2 14.6654 4.98402 14.6654 8.665Z" stroke="#008CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2730_22111">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
</template>