const ITEM_SELECTOR = 'js-navigation-item'
const SECTION_SELECTOR = 'js-navigation-section'

const ACTIVE_CLASS = 'is-active'

export const navigationScroll = {
  mounted: (node: HTMLElement): any => {
    const items = [...node.getElementsByClassName(ITEM_SELECTOR)] as HTMLElement[]
    const sections = [...node.getElementsByClassName(SECTION_SELECTOR)] as HTMLElement[]

    items.forEach(el => {
      el.addEventListener('click', () => {
        const target = el.dataset.href
        const section = sections.find(s => s.dataset.id === target)

        if (section) {
          section.scrollIntoView({ behavior: 'smooth' })
        }
      })
    })

    const onScroll = () => {
      const scrollPosition = window.scrollY

      sections.forEach(section => {
        if (scrollPosition >= section.offsetTop) {
          items.forEach(item => {
            item.classList.remove(ACTIVE_CLASS)
          })

          const target = section.dataset.id
          const item = items.find(s => s.dataset.href === target)

          if (item) {
            item.classList.add(ACTIVE_CLASS)
          }
        }
      })
    }

    window.addEventListener('scroll', onScroll)
  }
}
