import { defineAsyncComponent } from 'vue'
import {
  getRedirectResult,
  Auth,
  UserCredential,
  FacebookAuthProvider,
} from 'firebase/auth';

import { firebaseAuthRequest, firebaseCreateConnectRequest } from '@services/auth.service'
import { toggleLoader } from '@/modules/firebase/helpers';
import { useModalOpener } from '@/composables/useModalOpener'
import { usePromise } from '@/composables/usePromise'

const UiMessageModal = defineAsyncComponent(
  () => import('@/components/ui/modals/Message.vue')
)
const SocialDataComplete = defineAsyncComponent(
  () => import('@/modules/auth/components/SocialDataComplete.vue')
)
const TokenConfirmation = defineAsyncComponent(
  () => import('@/components/token/ConfirmationModal.vue')
)

import { i18n } from '@/i18n'
const { t: $t } = i18n.global

export async function handleRedirectResult(auth: typeof Auth): Promise<void> {
  // const { open: openSocialDataCompleteModal } = useModalOpener({
  //   name: 'TokenConfirmation',
  //   component: TokenConfirmation,
  //   // promise: toPromise,
  // });
  // openSocialDataCompleteModal();
  let result: typeof UserCredential | null = null
  try {
    result = await getRedirectResult(auth);
    toggleLoader(!!result);
    
    console.log(result, '>>>RESYLT1');
    if (!result) {
      setTimeout(() => {
        toggleLoader(false);
      }, 5000);
      return;
    }
    
    const credential = FacebookAuthProvider.credentialFromResult(result);

    const { user } = result
    console.log(result, user,  '>>>RESYLT2');

    if (window.IS_AUTH) {
      await firebaseCreateConnectRequest.execute({}, { token: user.accessToken });
      window.location.reload();
    } else {
      await firebaseAuthRequest.execute({}, { token: user.accessToken });
      window.location.href = window.cabinet_url;
    }
  } catch (e) {
    console.error(e, '>>>>>>>222>>>>>');

    const credential = FacebookAuthProvider.credentialFromError(e);
    console.log(credential, 'FACEBOOK ERROR');

    if (e instanceof Error && e.message === 'Network request failed') {
      // Handle specific errors if needed
    }
    const body = await e.json();
    const errors: { [key: string]: any } = {};
    body.errors.forEach((err: any) => {
      if (err.domain === 'request' && err.state) {
        Object.assign(errors, err.state);
      }
    });
    if (errors.phone) {
      const toPromise = value => usePromise(value)
        .then(async a => {
          console.log(a,'result');
          
          await firebaseAuthRequest.execute({}, { token: result.user.accessToken, ...a });
          window.location.href = window.cabinet_url
        })
        .catch(async (error) => {
          const body = await error.json();
          const errors: { [key: string]: any } = {};
          body.errors.forEach((err: any) => {
            if (err.domain === 'request' && err.state) {
              Object.assign(errors, err.state);
            }
          });
          const message = {
            title: errors.nonFieldErrors[0] ? errors.nonFieldErrors[0].message : 'Unexpected error',
          };
          const { open: openUiMessageModal } = useModalOpener({
            name: 'UiMessageModal',
            component: UiMessageModal,
            message,
            classes: 'modal--with-decor',
          });
          openUiMessageModal();
          toggleLoader(false);
          setTimeout(() => {
            toggleLoader(false);
          }, 2000);

        })
      const { open: openSocialDataCompleteModal } = useModalOpener({
        name: 'SocialDataComplete',
        component: SocialDataComplete,
        promise: toPromise,
        classes: 'modal--with-decor',
      });
      openSocialDataCompleteModal();
      return 
    }
    const message = {
      title: errors.nonFieldErrors[0] ? errors.nonFieldErrors[0].message : 'Unexpected error',
    };
    const { open: openUiMessageModal } = useModalOpener({
      name: 'UiMessageModal',
      component: UiMessageModal,
      message,
      classes: 'modal--with-decor',
    });
    openUiMessageModal();
    toggleLoader(false);
    setTimeout(() => {
      toggleLoader(false);
    }, 2000);
  }
}
