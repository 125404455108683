import {
  // Component as VueComponent,
  defineComponent,
  DefineComponent,
  // DefineComponent as VueComponent,
} from 'vue'
import {
  BemElement,
  BemClassesConfig,
} from '@aspectus/bem-classes'
import {
  BemReactiveClassesMixFunction,
  makeReactiveClasses,
} from './classes'
import { MadeProps } from './props'

export type VueComponent = DefineComponent // ReturnType<typeof defineComponent>

export function extendComponent<
  Element extends string,
  Modifier extends string,
  State extends string,
  Component extends VueComponent,
>(
  blockName: string,
  component: Component|any,
  elements: Element[] = [],
  modifiers: Modifier[] = [],
  states: State[] = [],
  config: BemClassesConfig = {
    e: '__',
    m: '--',
    v: '_',
  },
): [
  BemReactiveClassesMixFunction<BemElement, Element | 'root'>,
  (Component & { props: MadeProps<Element, State & Modifier> })
] {
  const [useClasses, propsDefinition] = makeReactiveClasses(
    config, blockName, elements, modifiers, states
  )

  return [
    useClasses,
    Object.assign(
      {},
      component,
      { props: Object.assign(
        // FIXME: Change this to be configurable.
        // Of course this can be overwritten by passed component properties,
        // but, hey!
        // Deal with it!
        // Challenge yourself!
        { as: { default: 'div' } },
        propsDefinition,
        component.props,
      ) },
    )
  ]
}
