import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { accessPermission } from '@/modules/user/permissions'
import { IS_DOCTOR } from '@/modules/user/accessTokens'

const PROFILE_PREFIX = 'cabinet:profile'
export const SETTINGS_BASE = 'cabinet:settings'
export const NOTIFICATIONS_BASE = 'cabinet:notifications'
const p = (name: string) => PROFILE_PREFIX + ':' + name
const s = (name: string) => SETTINGS_BASE + ':' + name
const n = (name: string) => NOTIFICATIONS_BASE + ':' + name

export const PROFILE_VIEW_NAME = p('view')
export const SECURITY_VIEW_NAME = s('view')
export const NOTIFICATIONS_SETTINGS_VIEW_NAME = s('notifications')
export const PROFILE_TYPE_VIEW_NAME = s('type')
export const NOTIFICATIONS_VIEW_NAME = n('view')

const ProfileView = () => import('./views/Profile.vue')
const SecurityView = () => import('./views/Security.vue')
const ProfileTypeView = () => import('./views/WhoYouAre.vue')
const NotificationsView = () => import('./views/Notifications.vue')
const NotificationsSettingsView = () => import('./views/NotificationsSettings.vue')

const BASE_MODEL_NAME = 'profile/'
const BASE_SETTINGS_MODEL_NAME = 'settings/'
const BASE_NOTIFICATIONS_MODEL_NAME = 'notifications/'

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  const { app } = options
  app.config.globalProperties.$router_notifications_view_name = NOTIFICATIONS_VIEW_NAME

  return [
    route('', ProfileView, PROFILE_VIEW_NAME, {
      meta: {
        title: () => $t('Manage your profile'),
      },
    }),
    route('type/', ProfileTypeView, PROFILE_TYPE_VIEW_NAME, {
      meta: {
        title: () => $t('Who you are?'),
        ...accessPermission([IS_DOCTOR], options),
      },
    }),
    
  ]
}


export function createNotificationsRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', NotificationsView, NOTIFICATIONS_VIEW_NAME, {
      meta: {
        title: () => $t('My notifications'),
      },
    }),    
  ]
}
export function createSettingsRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('security/', SecurityView, SECURITY_VIEW_NAME, {
      meta: {
        title: () => $t('Security'),
        ...accessPermission([IS_DOCTOR], options),
      },
    }),
    route('notifications/', NotificationsSettingsView, NOTIFICATIONS_SETTINGS_VIEW_NAME, {
      meta: {
        title: () => $t('Setting up notifications'),
      },
    }),
    
  ]
}

export { BASE_MODEL_NAME, BASE_SETTINGS_MODEL_NAME, BASE_NOTIFICATIONS_MODEL_NAME }