import { toArray, isObject } from './utils'

export type ModifierValue = ModifierValueObject | ModifierValueArray | string | boolean | null | undefined
export type ModifierValueObject = Object & {
  [x: string]: boolean
}
export type ModifierValueArray = Array<ModifierValue>
export type ModifiersDefinition = {
  [x: string]: ModifierValue
}

const e = Object.entries

const modifiedEntryCheck = (value: ModifierValue): boolean => (
  isObject(value) ? Object.keys(value || {}).length === 0 : !!value
)

/**
 * Simplifying modifiers input.
 *
 * @category Functions
 * @export
 * @param definition - Modifiers definition object to simplify.
 * @returns Array of modifier keys or key-value pairs.
 */
export function simplifyModifiers(definition: ModifiersDefinition = {}) {
  return e(definition).reduce<Array<string[]>>((acc, [key, value]) => {
    const values: Array<boolean | string> = isObject(value)
      ? e(value as ModifierValueObject).filter(([, value]) => modifiedEntryCheck(value)).map(x => x[0])
      : toArray(value).filter(modifiedEntryCheck)

    values.flat()
      .forEach(val => acc.push(val === true ? [key] : [key, val as string]))

    return acc
  }, [])
}

/**
 * Applying modifiers to an element class definition.
 *
 * @category Functions
 * @export
 * @param element - Element class name to apply modifiers to.
 * @param definition - Modifiers definition object.
 * @param modifierDelimiter - Modifier delimiter. Like '--', for example.
 * @param modifierValueDelimiter - Modifier value delimiter. Like '_' for example.
 * @returns Array of element with modifiers classnames.
 */
export function applyModifiers(
  element: string,
  definition: ModifiersDefinition = {},
  modifierDelimiter: string,
  modifierValueDelimiter: string
): string[] {
  const prepared = simplifyModifiers(definition)
    .map(modifier => modifier.join(modifierValueDelimiter))
  const prefix = element + modifierDelimiter

  return prepared.map(modifier => prefix + modifier)
}
