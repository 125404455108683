import { ModifiersDefinition, applyModifiers } from './modifiers'

/**
 * Block delimiters configuration object.
 */
export type BemBlockConfig = {
  /** Block namespace, like: 'o-'. */
  n?: string
  /** Block element delimiter, like: '__'. */
  e?: string
  /** Block modifier delimiter, like: '--'. */
  m?: string
  /** Block modifier value delimiter, like: '_'. */
  v?: string
}
type Element = string | null

function elementClassName(
  block: string, element: Element = null, namespace: string = '', elementDelimiter: string
): string {
  const stack = [(!namespace ? '' : namespace) + block]

  if (element) {
    stack.push(element)
  }

  return stack.join(elementDelimiter)
}

/**
 * Block classes generator.
 *
 * **Example:**
 * ```js
 * import { block } from '@aspectus/bem'
 *
 * const CONFIG = { n: '', e: '__', m: '--', v: '_' }
 * const blockName = block(CONFIG, 'block', null, {
 *   boolean: true,
 *   string: 'value',
 *   disabled: false,
 *   multiple: ['one', 'two'],
 *   object: {
 *     enabled: true,
 *     disabled: false,
 *     true: true,
 *   },
 *   emptyObject: {},
 * })
 *
 * console.log(blockName)
 * // > [
 * //   'block',
 * //   'block--boolean',
 * //   'block--string_value',
 * //   'block--multiple_one',
 * //   'block--multiple_two',
 * //   'block--object_enabled',
 * //   'block--object_true'
 * // ]
 * ```
 *
 * @category General
 * @export
 * @param config - Block delimiters config object.
 * @param block - Block name.
 * @param element - Element name or null if going to modify block itself.
 * @param modifiers - Modifiers definition.
 * @returns Array of block/element classes.
 */
export function block(
  config: BemBlockConfig,
  block: string,
  element: Element = null,
  modifiers: ModifiersDefinition = {},
): string[] {
  const { n, e, m, v } = config
  const className = elementClassName(block, element, n, e || '')
  const modifierClassNames = applyModifiers(className, modifiers, m || '', v || '')

  return [className].concat(modifierClassNames)
}
