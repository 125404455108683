import type { App } from 'vue'
import { VueRecaptchaPlugin } from 'vue-recaptcha/head'

export default {
  install(app: App<Element>): void {
    app
      .use(VueRecaptchaPlugin, {
        v2SiteKey: window.captcha_key,
        loaderOptions: {
          params: {
            hl: window.language,
          },
        },
      })
  },
}
