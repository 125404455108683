import { App } from 'vue'

import SearchComponents from './components'

export default {
  install(app: App<Element>): void {
    app
      .use(SearchComponents)
  }
}
