<template>

<component :is="as" type="button" :class="classes.root.value" v-bind="$attrs">
  <slot />
  <div :class="classes.icon.value" v-if="$slots.icon">
    <slot name="icon" :classes="classes">

    </slot>
  </div>
</component>

</template>

<script>

import { extendComponent } from '@aspectus/vue-bem-classes'
import multiproxy from '@aspectus/multiproxy'

const [useClasses, Component] = extendComponent('up-crop-ui-button', {
  props: { as: { default: 'button' } },
  setup(props) {
    const proxiedProps = multiproxy(props)
    const classes = useClasses(proxiedProps)

    return { classes }
  }
}, ['icon'])

export default Component

</script>

<style lang="sass">

.up-crop-ui-button
  position: relative
  user-select: none

  background: rgba(black, 0.4)
  border: 0
  color: white
  display: flex
  align-items: center
  justify-content: center
  height: 36px
  padding: var(--space-1) var(--space-3)
  cursor: pointer
  transition: background 0.5s

  &:hover
    background: black

  &:focus
    outline: none

  &--
    &appearance_
      &square
        width: 36px
        padding: 0

    &styling_
      &highlight
        background-color: var(--theme-accent-main)

</style>