import { computed, provide, ref, createApp, App, watch } from 'vue'
import {
  createRouter, 
  createWebHistory,
  useRoute,
  RouteParams,
  RouteLocationNormalizedLoaded,
  RouteRecordName,
} from 'vue-router'
import { useI18n } from 'vue-i18n'
import { createPinia } from 'pinia'

import createAppRouter from '@/router'
import install from '@/install'
import i18n, { i18n as instanceI18n } from './i18n'
import { CURRENT_LOCALE } from '@/const'
import { pluralization } from '@utils/intl-helpers'
import { getVideoId } from './utils/helpers'

import Components from '@/components'
import Modules from '@/modules'
import Plugins from '@/plugins'

import '@/utils'
import '@/modules/firebase/init'
import { useLikesStore } from '@m/store/likes'
// import Directives from '@/directives'

const { t } = instanceI18n.global

type CreateApplication = {
  createApp: typeof createApp
  createRouter: typeof createRouter
  createWebHistory: typeof createWebHistory
  createPinia: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({
    setup() {
      const route: RouteLocationNormalizedLoaded = useRoute()
      const { t: $t } = useI18n()

      const updatedTitle = ref<{ title: string; subTitle?: string } | null>(
        null
      )

      const currentTitle = computed(() => {
        if (updatedTitle.value?.title) {
          return updatedTitle.value
        }
        return 'function' === typeof route.meta.title
          ? route.meta.title?.(route.params as RouteParams) ||
              $t('MEDONLINE')
          : route.meta.title || $t('MEDONLINE')
      })

      provide('currentTitle', currentTitle)
      provide(
        'updateCurrentTitle',
        (title: { title: string; subTitle?: string } | null) => {
          console.log(title, 'updateCurrentTitle');
          updatedTitle.value = title
        }
      )

      // custom history.back logic
      const routeFromName = ref<RouteRecordName | null>(null)

      watch(
        () => route.name,
        (to, from) => {
          routeFromName.value = from
        }
      )

      provide(
        'previousRouteName',
        computed(() => routeFromName.value)
      )

      function iOS() {
        return (
          [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
          ].includes(navigator.platform) ||
          // iPad on iOS 13 detection
          (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        )
      }
      if (iOS()) {
        const tag = document.createElement('meta')
        tag.setAttribute('name', 'viewport')
        tag.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0,maximum-scale=1,user-scalable=0'
        )
        document.head.appendChild(tag)
      }
      return {}
    },

    created() {
      this.getLikesData()
    },

    methods: {
      getLikesData() {
        const likesStore = useLikesStore()

        likesStore.getLikesData()
      },
    },
  })

  app.config.compilerOptions.delimiters = ['[[', ']]']

  const pinia = createPinia()

  const router = createAppRouter({
    app,
    createInstance: createRouterInstance,
    createWebHistory,
    pinia,
  })

  app.config.globalProperties.$log = console.log // you can use $log in template
  app.config.globalProperties.$_locale = CURRENT_LOCALE // locale for $n format
  app.config.globalProperties.$pluralization = pluralization // (MAP: { one: 1; few: 3: many: 10}, count: number)
  app.config.globalProperties.$_getVideoId = getVideoId // can get { embedUrl, previewUrl } in template
  app.config.globalProperties.$goToTop = () =>
    document.body.scrollIntoView({ block: 'start', behavior: 'smooth' })
  app.config.globalProperties.$copyToClipboard = function copyToClipboard(
    value: string,
    callBack: (r: boolean) => void
  ) {
    // HTTPS ок localhost
    // use $toast after install it
    navigator.clipboard
      .writeText(value)
      .then(() => {
        callBack(true)
      })
      .catch(() => {
        callBack(false)
      })
  }
  app.config.compilerOptions.delimiters = ['[[', ']]'] // use this delimiters in pug(jinja)-templates

  app
    .use(install)
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(Components)
    .use(Modules)
    .use(Plugins)
    .mount('#app')


  return app
}
