import { friendlyUrlGenerator } from '@/packages/vue-friendly'
import { baseResource } from '@aspectus/resource'
import { urlConstructor } from '@/plugins/resource/api'
import { r, createSender } from '@/plugins/resource/resource'

// Models
const MODEL = 'articles'

export const ARTICLES_CATALOG_URL = `/${MODEL}/`
export const ARTICLES_RATING_SET_URL = `/posts/rate/{id}/`

// filter resources
export const articlesCatalogResource = friendlyUrlGenerator([
  ARTICLES_CATALOG_URL, 'ajax'
], baseResource)
export const articlesRatingSetResource = r(ARTICLES_RATING_SET_URL, MODEL, createSender)

export const ARTICLES_LIST_URL: string = urlConstructor(MODEL+'/list/', ['search', 'ids', 'is_favorite'])