import { App, defineAsyncComponent } from 'vue';
import { vIntersectionObserver, vOnClickOutside } from '@vueuse/components';
import {
  // Directives
  vTooltip,
  // Components
  Dropdown,
} from 'floating-vue'
import Directives from '@/directives'
import VueDatePicker from 'vue-datepicker-next';
import VueSlider from 'vue-slider-component'
import VueRouterPermission from '@/packages/vue-router-permissions'

import VueSilentbox from '@/packages/silent-box/main'
// import 'vue-silentbox/dist/style.css'

import 'vue-datepicker-next/index.css';
// require(`vue2-datepicker/locale/${window.language}`);

export default {
  install(app: App<Element>): void {
    app
      .directive('on-click-outside', vOnClickOutside)
      .directive('intersection-observer', vIntersectionObserver)
    app
      .component('VDropdown', Dropdown)
      .component('DatePicker', VueDatePicker)
      .component('VueSlider', VueSlider)
      .use(VueSilentbox)
      .use(VueRouterPermission)
      .directive('tooltip', vTooltip)

    Directives.register(app)
  },
}
