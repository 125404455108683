import { App, defineAsyncComponent } from 'vue'

const iconsComp = require.context('@/components/i/', true, /\.vue$/)

const install = (app: App<Element>): void => {
  for (const comp of iconsComp.keys()) {
    const name = comp.replace(/^\.\//, '').replace(/\.\w+$/, '')

    app.component(`I${name}`, defineAsyncComponent(() => import(`@/components/i/${name}.vue`)))
  }  
}

export default {
  install,
}
