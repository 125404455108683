
import { computed } from 'vue'
import { extendComponent } from '@aspectus/vue-bem-classes'
import multiproxy from '@aspectus/multiproxy'

// eslint-disable-next-line
const [useClasses, Component] = extendComponent('up-progress', {
  props: { value: { type: Number, default: 0 } },
  setup(props: any) {
    const proxiedProps = multiproxy(props)
    const classes = useClasses(proxiedProps)
    const percentage = computed(() => `${(proxiedProps.value * 100) | 0}%`)
    const as = "up-progress"

    return { classes, percentage }
  }
}, ['bar'])
export default Component

