import { App } from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'

export default {
  install(app: App<Element>): any {
    app.use(VueGoogleMaps, {
      load: {
        key: window.GOOGLE_MAPS_KEY || '',
        language: window.language,
      },
    })
  }
}
