import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered is-textarea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      autocomplete: "off",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.val) = $event)),
      onInput: $setup.setValue
    }), null, 16), [
      [_vModelText, $setup.val]
    ]),
    _renderSlot(_ctx.$slots, "label")
  ]))
}