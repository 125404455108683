/* eslint-disable no-restricted-globals */
// import { pick } from 'ramda'
import { defineComponent, ref, Ref, onMounted, watch } from 'vue'

import { useRoute } from 'vue-router'

import { urlGetter } from '@/packages/vue-friendly'
import { Pagination } from '@/const'

const same = (x: any) => x
export default defineComponent({
  name: 'url-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({ filters: {} }),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
    baseUrl: {},
    order: {
      type: Array,
      default: () => ['prefix', 'filters', 'page']
    },
    queryOrder: {
      type: Array,
      default: () => ['search']
    },
  },
  setup(props, { slots }) {
    const route = useRoute()
    const parameters: Ref<{ filters?: Record<string, any>; } & Partial<Pagination>> = ref(props.initial)
    const friendlyUrl = ref<any>({
      filters: {},
    })
    const order = {
      path: props.order,
      query: props.queryOrder
    }
    const urlWithoutPage = ref(props.baseUrl)
    onMounted(() => {
      window.addEventListener('popstate', () => {
        parameters.value = props.from({
          ...props.initial,
        })
      })
      parameters.value = props.from({
        ...props.initial,
      })
      urlWithoutPage.value = urlGetter(
        [props.baseUrl],
        order,
        { ...parameters.value, search: undefined, page: undefined, limit: undefined, offset: undefined }
      )
      
    })

    watch(() => route.path, () => {
      parameters.value = props.from({
        ...props.initial,
      });
    })

    function updateUrl(params: { filters: Record<string, any>; } & Partial<Pagination>) {
      const query: { filters: Record<string, unknown | unknown[]> } = props.to(params)
      const zero = 0
      friendlyUrl.value = query || {}
      const keys = Object.keys(friendlyUrl.value.filters || {})
      keys.forEach(x => {
        if (undefined !== friendlyUrl.value.filters[keys[x]]) {
          if (zero === (friendlyUrl.value.filters[keys[x]] as []).length) {
            delete friendlyUrl.value.filters[keys[x]]
          }
        }
      })

      urlWithoutPage.value = urlGetter(
        [props.baseUrl],
        order,
        { ...friendlyUrl.value, search: undefined, page: undefined, limit: undefined, offset: undefined }
      )
      const shift = 1
      const currentPage =
        Math.floor(Number(params.offset) / Number(params.limit)) + shift
      const page = currentPage > shift ? currentPage : null
      // const base = Object.keys(params.filters).length ? `${this.baseUrl}filters` : this.baseUrl;
      // const fullUrl = urlGetter([base], order, { ...params, page });
      const fullUrl = urlGetter(
        [props.baseUrl],
        order,
        { ...friendlyUrl.value, page }
      )

      history.pushState(
        { url: fullUrl },
        '',
        fullUrl
      )
    }

    function changeParameters(value: { filters: Record<string, any>; } & Partial<Pagination>) {
      parameters.value = value
      updateUrl(parameters.value as { filters: Record<string, any>; } & Partial<Pagination>)
    }

    return () =>
      slots.default?.({
        parameters,
        changeParameters,
        updateUrl,
        urlWithoutPage,
      })
  },
})
