export const TEST_DOMAINS = [
  'pl-eska.webcase-dev.com',
  'sk-eska.webcase-dev.com',
  'uk-eska.webcase-dev.com',
]
export const TEST_DOMAINS_WITH_LOCAL = [
  'eska.localhost',
  ...TEST_DOMAINS
]

export const OFFERS_SORT_MAP = {
  up: 'price_monthly_up',
  down: 'price_monthly_down',
}

export const PIN_ICON = '/static/app/images/marker.png'

export const PATIENT_KEY = 'patient'
export const DOCTOR_KEY = 'doctor'
export const COUNTRY_CODE_COOKIE_NAME = 'MEDONLINE_COUNTRY_COOKIE_NAME'
export const SELECTED_CITY_COOKIE_NAME = 'MEDONLINE_SELECTED_CITY'

export const MAXIMUM_VIDEO_IN_BRANCH = 5
export const MAXIMUM_PHOTO_IN_BRANCH = 10
export const MAXIMUM_AWARDS_DOCTOR = 10
export const MAXIMUM_VIDEO_DOCTOR = 5
export const MAXIMUM_SIZE_PHOTO_IN_BRANCH = 10 * 1024 * 1024
export const MAXIMUM_SIZE_IMAGE_AWARD_DOCTOR = 10 * 1024 * 1024
export const MAXIMUM_SIZE_FILE_VACANCY = 50 * 1024 * 1024

export const LOCAL_STORAGE_OFFERS_VIEW = 'local-storage-offers-view-controller'
export const LOCAL_STORAGE_CABINET_TABLE_VIEW =
  'local-storage-table-view-controller'

type PaginationItemValue = string | number

export type Pagination = {
  limit: PaginationItemValue
  offset: PaginationItemValue
  total: PaginationItemValue
}

export const COMMERCIAL_OFFER_SOURCE_TYPE = {
  cabinet: 'cabinet',
  offer: 'offer',
  financing_service: 'financing_service',
}

export const noticeStatus = {
  UNREAD: 73232,
  READ: 73236,
  IMPORTANT: 20,
}

export const MO_ACCESS_TOKEN_COOKIE_NAME = 'MO:ACCESS_TOKEN_COOKIE'
export const MO_REFRESH_TOKEN_COOKIE_NAME = 'MO:REFRESH_TOKEN_COOKIE'

export const MO_IS_AUTH_COOKIE_NAME = 'MO:IS_AUTH_COOKIE_NAME'

export const MO_BRANDS_LOCAL_STORAGE_KEY = `mo-brands-${window.language}`
export const MO_BRAND_MODELS_LOCAL_STORAGE_KEY = `mo-brand-models-${window.language}`

export const CURRENT_LOCALE = `${
  window.language
}-${window.country_code.toUpperCase()}`

export const FinancingApplicationStatusGroupKeys = {
  creditDecision: 'creditDecision',
  contract: 'contract',
  payments: 'payments',
  activeDeals: 'activeDeals',
  canceled: 'canceled',
}
export const FinancingApplicationStatusGroup = {
  [FinancingApplicationStatusGroupKeys.creditDecision]: 'credit_decision',
  [FinancingApplicationStatusGroupKeys.contract]: 'contract',
  [FinancingApplicationStatusGroupKeys.payments]: 'payments',
  [FinancingApplicationStatusGroupKeys.activeDeals]: 'active_deals',
  [FinancingApplicationStatusGroupKeys.canceled]: 'canceled',
}
