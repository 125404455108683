import { App } from 'vue'

import Validation from './validation/plugin'
import Auth from './auth'
import Uploading from './uploading/plugin'
import Search from './search'

export default {
  install(app: App<Element>): void {
    app
      .use(Validation)
      .use(Auth)
      .use(Uploading)
      .use(Search)
  }
}
