import { reactive, toRef } from 'vue'

export const state = (count?: number) => ({
  count: count || 0,
  loading: !!count,
})

export type State = ReturnType<typeof state>

export const makeState = (count?: number) => reactive(state(count))

export function startLoading(context: State): boolean {
  if (context.count === 0) {
    context.loading = true
  }

  context.count++

  return context.loading
}

export function stopLoading(context: State): boolean {
  context.count--

  if (context.count === 0) {
    context.loading = false
  }

  return context.loading
}

export function makeLoading(count?: number) {
  const state = makeState(count)
  const loading = toRef(state, 'loading')

  function load<T>(promise: Promise<T>) {
    startLoading(state)
    console.log(promise);
    if (!promise) return 
    return promise.finally(() => stopLoading(state))
  }

  return [state, load, loading] as [typeof state, typeof load, typeof loading]
}
