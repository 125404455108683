<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M2 22H22" stroke="url(#paint0_linear_2498_20202)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17 2H7C4 2 3 3.79 3 6V22H21V6C21 3.79 20 2 17 2Z" stroke="url(#paint1_linear_2498_20202)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.0602 15H9.93022C9.42022 15 8.99023 15.42 8.99023 15.94V22H14.9902V15.94C15.0002 15.42 14.5802 15 14.0602 15Z" stroke="url(#paint2_linear_2498_20202)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 6V11" stroke="url(#paint3_linear_2498_20202)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.5 8.5H14.5" stroke="url(#paint4_linear_2498_20202)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_2498_20202" x1="26.181" y1="20.8121" x2="26.2285" y2="23.0311" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2498_20202" x1="24.7629" y1="-21.7578" x2="41.9686" y2="14.4713" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2498_20202" x1="16.2448" y1="6.68476" x2="22.4507" y2="19.1303" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint3_linear_2498_20202" x1="13.2091" y1="0.0605411" x2="17.4701" y2="2.05437" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
    <linearGradient id="paint4_linear_2498_20202" x1="15.5453" y1="7.31211" x2="15.7337" y2="9.51602" gradientUnits="userSpaceOnUse">
      <stop stop-color="#008CFF"/>
      <stop offset="1" stop-color="#41DEBD"/>
    </linearGradient>
  </defs>
</svg>
  </template>