import { SetupContext, computed, defineComponent, h, ref, watch } from 'vue'
// import { hasAccess as hasAccessChecker } from '@aspectus/permissions'
import { useRouter } from 'vue-router'

import { collectRoutePermissionCheckers } from './routing'

export function hasAccessChecker(permissions, ...args) {
  return Promise.all(permissions.map(x => x.onHasAccess.apply(x, args)));
}


export default defineComponent({
  props: {
    to: {},
  },
  setup(props, context: SetupContext) {
    const hasAccess = ref(false)

    const router = useRouter()
    const permissions = computed(() => {
      return collectRoutePermissionCheckers(router.resolve(props.to)).map(
        (x) => x[0]
      )
    })
    watch(
      permissions,
      (newVal) => {
        setAccess(newVal)
      },
      {
        immediate: true,
      }
    )
    function setAccess(perm = permissions.value) {
      hasAccessChecker(perm || [], null, {
        pinia: window.app.config.globalProperties.$pinia,
      })
        .then((res) => {
          hasAccess.value = true
        })
        .catch(() => {
          hasAccess.value = false
        })
    }
    // return () => context.slots.default()
    return () =>
      hasAccess.value
        ? context.slots.default?.({
            ...props,
            ...context.attrs,
            hasAccess: hasAccess.value,
          })
        : null
  },
})
//   mergeProps({ checker: () => RouteAccessState }, PermissionsAvailable.props),
//   { name: 'route-available' }
// );
