<script>
import { h, compile, shallowRef } from 'vue'


export default {
  name: 'AppRenderer',
  props: ['result', 'dataRes'],
  data() {
    return {
      component: '',
    }
  },
  watch: {
    result: {
      handler(nval) {
        if (nval) {
          if ('object' === typeof nval) {
            this.component = nval.val
          } else {
            const value = nval
              .replaceAll('[[', '{{')
              .replaceAll(']]', '}}')
              // .replaceAll('&#34;', "'")
            this.component = shallowRef(compile(value))
          }
        }
      },
      immediate: true,
    },
  },
  render() {
    if ('object' === typeof this.result) {
      if (this.dataRes) {
        return h('component', {
          is: this.component,
          props: { dataResult: this.dataRes },
        })
      }
      return h('div', this.$slots.render)
    }
    if (this.result) {
      return h({ render: this.component })
    }
    return h('div', this.$slots.render)
  },
}
</script>
