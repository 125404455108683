import { partialRight } from 'ramda'
import { prefixAPI } from '@/plugins/resource/api'
import { markRaw, h } from 'vue'
import { useModalOpener } from '@/composables/useModalOpener'

import {
  baseResource as superResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import {
  xCSRFMiddleware,
  multipartRequestMiddleware,
  jsonTransformer,
  defaultDataTransformer,
  raiseHttpErrorTransformer,
  superagentJsonTransformer,
} from '@aspectus/resource-commons'

import makeTemplateGetter from '@aspectus/resource-template-url-getter'

import fetcher from '@aspectus/superagent-fetch'
import { HEADERS_MIDDLEWARE } from './middleware'

import { simpleTransformer } from './transformers'

import UiMessageModal from '@/components/ui/modals/Message.vue'

import { i18n as instanceI18n } from '@/i18n'

export type BaseResource = typeof baseResource

const { t } = instanceI18n.global

function handleServerError(response: Response) {
  if (500 === response.status) {
    const message = {
      title: t('Server error'),
      text: t('Api:') + " " + response.url,
    }

    const { open: openMessageModal } = useModalOpener({
      component: markRaw(UiMessageModal) as unknown as any,
      message,
      classes: 'modal--with-decor',
    })
    
    openMessageModal()
    throw response
  } else if (503 === response.status) {
    console.log(response.body);
    response.text().then(res => {
  
      const { open: openMessageModal } = useModalOpener({
        component: h('div', h('iframe', { srcdoc: res })),
        classes: 'modal--with-decor',
      })
      
      openMessageModal()
    })
    throw response
  }
  return response
}

export const baseResource = superResource
  .middleware(xCSRFMiddleware)
  .middleware(HEADERS_MIDDLEWARE)
  .transform(handleServerError)
  .transform(raiseHttpErrorTransformer)

export const simpleResource = baseResource
  .transform(simpleTransformer)

export const receiveResource = baseResource
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)

export const sendResource = baseResource
  .middleware(jsonRequestMiddleware)
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)
  .config('method', 'POST')

export const sendFileResource = baseResource
  .fetcher(fetcher)
  .middleware(multipartRequestMiddleware)
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .config('method', 'POST')

export const destroyResource = baseResource
  .config('method', 'DELETE')
  
export const updateResource = sendResource
  .config('method', 'PATCH')

export const createResource = (
  template: string,
  base: typeof baseResource = baseResource
): typeof baseResource => base.url(makeTemplateGetter(template))

export const createReceiver = partialRight(createResource, [receiveResource])
export const createRemover = partialRight(createResource, [destroyResource])
export const createSender = partialRight(createResource, [sendResource])
export const createUpdater = partialRight(createResource, [updateResource])
export const createFileSender = partialRight(createResource, [sendFileResource])
export const createSimpleSender = partialRight(createResource, [simpleResource])

export const r = (tpl: string, model = '', f: typeof baseResource = createReceiver): typeof baseResource =>
  f(prefixAPI(tpl, model))
