import { MEDIA_TAB_KEY, SCHEDULE_TAB_KEY } from '@m/cabinet/modules/Branch/const'

export const GENERAL_TAB_KEY: string = 'general'
export const SPECIALIZATION_TAB_KEY: string = 'services'
export const DOCUMENTS_TAB_KEY: string = 'documents'
export const PLACE_OF_EMPLOYMENT_TAB_KEY: string = 'place_of_employment'


export enum StatusModerationChoices {
  IN_PROCESS = 'in_process',
  AWAITING_MODERATION = 'awaiting_moderation',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export const GET_MODERATION_STATUSES = t => ([
  {
    value: StatusModerationChoices.IN_PROCESS,
    label: t('In process of filling out information'),
    icon: 'i-status-process',
    color: '#B81ED1',
  },
  {
    value: StatusModerationChoices.AWAITING_MODERATION,
    label: t('Awaiting moderation'),
    icon: 'i-status-waiting-moderation',
    color: '#008CFF'
  },
  {
    value: StatusModerationChoices.APPROVED,
    label: t('Doctor approved'),
    icon: 'i-status-active',
    color: '#1ED1AC',
  },
  {
    value: StatusModerationChoices.REJECTED,
    label: t('Moderation was not successful'),
    icon: 'i-status-failed',
    color: '#E74C3C',
  },
])