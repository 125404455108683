import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), _mergeProps({
    class: _ctx.classes.root.value
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.classes.bar.value),
        style: _normalizeStyle({ width: _ctx.percentage })
      }, null, 6)
    ]),
    _: 1
  }, 16, ["class"]))
}